import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import shareprofile from "../../../assets/images/contact_friend.png";
import bannergirl from "../../../assets/images/bannergirl.jpg";
import arrow from "../../../assets/images/arrow_withoutcircle_white.svg";
import share from "../../../assets/images/share_profile.svg";
import play from "../../../assets/images/play_icon.svg";
import styles from "../styles/style.module.css";
import Slider from "react-slick";
import { getPostsData } from "../../Posts/api";
import { PostsResponse } from "../../Posts/interface";
import { AD_TYPE } from "../../../utils/enums/types";
import moment from "moment";

function Techschool() {
  const [posts, setPosts] = useState<PostsResponse[]>([]);
  const navigate = useNavigate();

  const handleGoToNext = () => {
    navigate("/feeds");
  };
  const getPosts = async () => {
    const response = await getPostsData(AD_TYPE.TECH_SCHOOL_AD);
    setPosts(response.data);
  };
  useEffect(() => {
    getPosts();
  }, []);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 757,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="recommendedjobs share_connect_friends">
        <div className="row align-items-center justify-content-between">
          <Slider {...settings}>
            {posts?.length > 0 ? (
              posts?.map((data, ind) => {
                return (
                  <div
                    key={ind}
                    className="col border rounded-3 pt-3 text-center overflow-hidden"
                  >
                    <div className={styles.textstatusongoing}>
                      <span className="text-center text-right bg-blue">
                        {/* {data?.status} */}
                        free
                      </span>
                    </div>
                    <div className="text-start px-lg-3 px-2">
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="col-8">
                          <div className="newheadingstyle text-start d-flex align-items-center">
                            <figure>
                              <img
                                src={
                                  data?.user?.profilePicture
                                    ? data?.user?.profilePicture
                                    : shareprofile
                                }
                                alt=""
                                width={50}
                                height={50}
                              />
                            </figure>
                            <div className="ps-lg-3 ps-2">
                              <h3 className="mb-0 text-16 fw-normal">
                                {data?.user?.name}
                              </h3>
                              <p className="text-12 mb-2">
                              {moment.utc(data.createdAt).fromNow()}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <i className="bg-lightgrey shadow-sm rounded-circle p-2">
                            <img src={share} className="img-fluid" />
                          </i>
                        </div>
                      </div>
                      <div className="col-12">
                        <h4 className="text-14 fw-semibold">Programe:</h4>
                        <p className="text-14 text-black mb-2">
                          {data?.description}
                        </p>
                        <h4 className="text-14 fw-semibold mb-2">
                          Chapters : 2:
                        </h4>
                      </div>
                    </div>
                    <div className="mt-3 position-relative">
                      <div className="">
                        <figure className="mb-0">
                          <img src={bannergirl} alt="" className="img-fluid" />
                        </figure>
                        <i className="position-absolute top-0 bottom-0 start-0 end-0 mx-auto shadow-sm rounded-circle d-flex align-items-center justify-content-center p-3">
                          <img src={play} alt="" />
                        </i>
                      </div>

                      <div className="col-12 p-2 bgprimary position-absolute bottom-0 d-flex align-items-center justify-content-between">
                        <p className="text-16 mb-0 text-white fw-semibold">
                          Learn More
                        </p>
                        <i>
                          <img src={arrow} alt="" width={20} height={20} />
                        </i>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No data added yet..!</p>
            )}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Techschool;