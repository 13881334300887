import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, Modal, ModalFooter } from "react-bootstrap";
import penbl from "../../../assets/images/penbl.png";
import upload from "../../../assets/images/upload.svg";
// import styles from "../styles/style.module.css";
import blackarrow from "../../../assets/images/blackarrow.svg";
import whitearrow from "../../../assets/images/whitearrow.svg";
import thumb from "../../../assets/images/profile_picture.png";
import styles from "../styles/style.module.css";
import { IOption } from "../../../Interfaces";
import { updateUserProfile } from "../../home/api";
import { getMasterTypes } from "../../../feature/auth/signup/api";
import { MASTER_TYPE, SKILL_TYPE } from "../../../utils/enums/masterTypes.enum";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TechSkills } from "../interface";
import { uploadFileImg } from "../../Posts/api";
import { getManufacturerModelList } from "../../work/api";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  refresh: () => void;
  secondSkill: TechSkills[];
}

const SecondSkillModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  refresh,
  secondSkill,
}) => {
  if (!isOpen) return null;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [occupation, setOccupation] = useState("");
  const [occupationId, setOccupationId] = useState("");
  const [occuData, setOccuData] = useState<IOption[]>([]);
  const [newAbout, setNewAbout] = useState<any>("");
  const [manufacturer, setManufacturer] = useState("");
  const [manufacturerId, setManufacturerId] = useState("");
  const [manufacturerData, setManufacturerData] = useState<IOption[]>([]);
  const [model, setModel] = useState("");
  const [modelId, setModelId] = useState("");
  const [modelData, setModelData] = useState<IOption[]>([]);
  const [img, setImg] = useState<any>("");
  const [type, setType] = useState<string>("");
  const [index, setIndex] = useState<number>();

  const editorConfiguration = {
    toolbar: ["undo", "redo", "|", "bold", "italic"],
    height: "150px",
  };

  useEffect(() => {
    secondSkill?.map((data, ind) => {
      if (data?.type === "second") {
        // If there is initial data, pre-fill the form fields
        setOccupation(data?.occupationDetail?.name || "");
        setOccupationId(data?.occupationDetail?._id || "");
        setNewAbout(data?.description || "");
        setManufacturer(data?.manufacturerDetail?.name || "");
        setManufacturerId(data?.manufacturerDetail?._id || "");
        setModel(data?.manufacturerModelDetail?.name || "");
        setModelId(data?.manufacturerModelDetail?._id || "");
        setImg(data?.image || "");
        setType(data?.type || "");
        setIndex(ind);
      }
    });
  }, [secondSkill]);

  const loadDropDowns = async () => {
    try {
      const occupationDataRes = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
      const manufacturerRes = await getMasterTypes(MASTER_TYPE.MANUFACTURERS);

      if (occupationDataRes?.data || manufacturerRes?.data) {
        setOccuData(
          occupationDataRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setManufacturerData(
          manufacturerRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to load data", error);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleOccupationChange = (label: string, value: string) => {
    setOccupation(label);
    setOccupationId(value);
  };

  const handleManufacturerChange = async (label: string, value: string) => {
    setManufacturer(label);
    setManufacturerId(value);
    const modelRes = await getManufacturerModelList(
      MASTER_TYPE.MANUFACTURERS_MODELS,
      value
    );
    if (modelRes?.data) {
      setModelData(
        modelRes?.data.map((x: any) => ({
          label: x.name,
          value: x._id,
        }))
      );
    }
  };

  const handleModelChange = (label: string, value: string) => {
    setModel(label);
    setModelId(value);
  };

  const handleSubmitCustomOccupation = async () => {
    // const payload = {
    //   type: MASTER_TYPE.OCCUPATIONS,
    //   name: searchTerm,
    //   alias: searchTerm,
    // };
    // toast.promise(postMasterType(payload), {
    //   pending: {
    //     render() {
    //       return MESSAGES.POSTING_CONTRACTS.VERIFYING;
    //     },
    //   },
    //   success: {
    //     render(newOccupation) {
    //       formik.setFieldValue(
    //         "primaryOccupation",
    //         newOccupation.data.data.name
    //       );
    //       setFilterValue("");
    //       setSearchTerm("");
    //       dispatch(
    //         setOccupation({
    //           ...occupation,
    //           primaryOccupation: {
    //             label: newOccupation.data.data.name,
    //             value: newOccupation.data.data._id,
    //           },
    //         })
    //       );
    //       return MESSAGES.POSTING_CONTRACTS.ADDING;
    //     },
    //   },
    //   error: {
    //     render(error) {
    //       let errorMesage = error as APIResponseError;
    //       return errorMesage?.data?.data?.message;
    //     },
    //   },
    // });
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("files", img);
      });
      try {
        const response = await uploadFileImg(formData);
        const newImageUrl = response.data.files[0];
        setImg(newImageUrl);
      } catch (err) {}
    }
  };

  const removeUploadedFiles = (): void => {
    // if (fileIndex >= 0 && fileIndex < img.length) {
    //   const updatedFiles = img.filter((_, index) => index !== fileIndex); // Remove the image at the specified index
    //   setImg(""); // Update the state with the new array
    // }
    setImg("");
  };

  const handleUpdateSkill = async () => {
    if (occupationId || newAbout || manufacturerId || img) {
      const payload = {
        description: newAbout,
        type: SKILL_TYPE.SECOND,
        image: img,
        manufacturerId: manufacturerId,
        manufacturerModelId: modelId,
        occupationId: occupationId,
      };

      try {
        let existingData: any = [];
        if (secondSkill?.length && type === "second") {
          existingData = secondSkill?.map((data, ind) => {
            if (ind === index) {
              return {
                description: payload?.description,
                type: payload?.type,
                image: payload?.image,
                manufacturerId: payload?.manufacturerId,
                manufacturerModelId: payload?.manufacturerModelId,
                occupationId: payload?.occupationId,
              };
            } else {
              return {
                description: data?.description,
                type: data?.type,
                image: data?.image,
                manufacturerId: data?.manufacturerDetail?._id,
                manufacturerModelId: data?.manufacturerModelDetail?._id,
                occupationId: data?.occupationDetail?._id,
              };
            }
          });
        } else {
          if (secondSkill?.length) {
            existingData = secondSkill?.map((data, ind) => {
              return {
                description: data?.description,
                type: data?.type,
                image: data?.image,
                manufacturerId: data?.manufacturerDetail?._id,
                manufacturerModelId: data?.manufacturerModelDetail?._id,
                occupationId: data?.occupationDetail?._id,
              };
            });
          }
          existingData = [...existingData, payload];
        }

        let updatedSkills = {
          technicalSkills: existingData,
        };
        await updateUserProfile(updatedSkills);
        refresh();
        onClose();
      } catch (error) {
        console.log("Error occuring while updating Top skill's data", error);
      }
    }
  };

  const occuOptions =
    filterValue && filterValue !== ""
      ? occuData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : occuData;

  const manufacturerOptions =
    filterValue && filterValue !== ""
      ? manufacturerData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : manufacturerData;

  const modelOptions =
    filterValue && filterValue !== ""
      ? modelData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : modelData;

  return (
    
    <>
    <Modal show={isOpen} onHide={onClose} centered size="lg">
     <Modal.Header closeButton>
       <Modal.Title>{title}</Modal.Title>
     </Modal.Header>
     <form>
       <Modal.Body>       
       <div className="addModalPro">
                <div className="row">
                  <div className="col-12">
                    <div className="formGroup">
                      <label>Occupation</label>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                          >
                            {occupation || "Please select an option"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className="">
                              <FormControl
                                autoFocus
                                className="mx-3 my-2 w-auto"
                                placeholder="Search..."
                                onChange={(e) => setFilterValue(e.target.value)}
                                value={filterValue}
                              />
                            </div>

                            <div
                              style={{
                                maxHeight: "300px",
                                // overflowY: "scroll",
                              }}
                            >
                              {occuOptions.length === 0 ? (
                                <Dropdown.Item disabled>
                                  No options found
                                </Dropdown.Item>
                              ) : (
                                [
                                  {
                                    label: "Select primary occupation",
                                    value: "",
                                  },
                                  ...occuOptions,
                                ].map((option, index: number) => (
                                  <>
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        handleOccupationChange(
                                          option.label,
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  </>
                                ))
                              )}

                              <div className={styles.addOccuatin}>
                                <div className="col-8">
                                  <input
                                    type="text"
                                    placeholder="Type an occupation here you want to add..."
                                    name="customOccupation"
                                    value={searchTerm}
                                    onChange={(e) =>
                                      setSearchTerm(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleSubmitCustomOccupation();
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="formGroup">
                      <label>About</label>
                      <div>
                        <CKEditor
                          editor={ClassicEditor}
                          data={newAbout}
                          config={editorConfiguration}
                          onChange={(event, editor) =>
                            setNewAbout(editor.getData())
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="formGroup">
                      <label>Manufacturer</label>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                          >
                            {manufacturer || "Please select an option"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className="">
                              <FormControl
                                autoFocus
                                className="mx-3 my-2 w-auto"
                                placeholder="Search..."
                                onChange={(e) => setFilterValue(e.target.value)}
                                value={filterValue}
                              />
                            </div>

                            <div
                              style={{
                                maxHeight: "300px",
                                // overflowY: "scroll",
                              }}
                            >
                              {manufacturerOptions.length === 0 ? (
                                <Dropdown.Item disabled>
                                  No options found
                                </Dropdown.Item>
                              ) : (
                                [
                                  {
                                    label: "Select primary occupation",
                                    value: "",
                                  },
                                  ...manufacturerOptions,
                                ].map((option, index: number) => (
                                  <>
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        handleManufacturerChange(
                                          option.label,
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  </>
                                ))
                              )}

                              <div className={styles.addOccuatin}>
                                <div className="col-8">
                                  <input
                                    type="text"
                                    placeholder="Type an occupation here you want to add..."
                                    name="customOccupation"
                                    value={searchTerm}
                                    onChange={(e) =>
                                      setSearchTerm(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleSubmitCustomOccupation();
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="formGroup">
                      <label>Model / Version / Controller</label>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                          >
                            {model || "Please select an option"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className="">
                              <FormControl
                                autoFocus
                                className="mx-3 my-2 w-auto"
                                placeholder="Search..."
                                onChange={(e) => setFilterValue(e.target.value)}
                                value={filterValue}
                              />
                            </div>

                            <div
                              style={{
                                maxHeight: "300px",
                                // overflowY: "scroll",
                              }}
                            >
                              {modelOptions.length === 0 ? (
                                <Dropdown.Item disabled>
                                  No options found
                                </Dropdown.Item>
                              ) : (
                                [
                                  {
                                    label:
                                      "Select Model / Version / Controller",
                                    value: "",
                                  },
                                  ...modelOptions,
                                ].map((option, index: number) => (
                                  <>
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        handleModelChange(
                                          option.label,
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  </>
                                ))
                              )}

                              <div className={styles.addOccuatin}>
                                <div className="col-8">
                                  <input
                                    type="text"
                                    placeholder="Type an occupation here you want to add..."
                                    name="customOccupation"
                                    value={searchTerm}
                                    onChange={(e) =>
                                      setSearchTerm(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleSubmitCustomOccupation();
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="formGroup">
                      <label>Upload Image</label>
                      <div
                        className={styles.uploadimagefile}
                      
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                          onChange={({ target }) =>
                            handleFileUpload(target.files)
                          }
                        />
                        <div className={styles.uploadimage}>
                          <i>
                            <img src={upload} alt="" />
                          </i>
                          <p className="mb-0 text-14 text-blue">
                            Upload jpeg/png file
                          </p>
                        </div>
                      </div>
                      {img && (
                        <li>
                          <div className={styles.uploadImages}>
                            <img src={img} alt="" className="img-fluid" />
                            <i
                              className="fas fa-times"
                              onClick={removeUploadedFiles} // Call the function to remove the image
                            ></i>
                          </div>
                        </li>
                      )}
                    </div>
                  </div>
                  
                </div>
            </div> 
       </Modal.Body>
       <ModalFooter>
         <div className="btn-hover-effect">
           <h6 onClick={onClose} className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
                             >
             <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
               Back
             </span>
             <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-lg-4 p-3">
               <img src={blackarrow} alt="" width={15} height={15} style={{transform: "rotate(-180deg)"}}/>
             </i>
           </h6>
         </div>
         <div className="btn-hover-effect">
           <h6 onClick={handleUpdateSkill} className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto">
             <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
               Save
             </span>
             <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-lg-4 p-3">
               <img src={whitearrow} alt="" width={15} height={15}/>
             </i>
           </h6>
         </div>
         
       </ModalFooter>
     </form>
     
    </Modal>
   </>
  );
};

export default SecondSkillModal;
