import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import EditServicesModal from "../modals/EditServicesModal";

const ServiceOffered = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Services Offered Offered
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img src={penbl} className="cursor-pointer ms-auto" alt="" onClick={openModal} />
          </span>
        </div>
      </div>
      <div className="">
        <h4 className="text-14 fw-semibold text-blue">+ Add more</h4>
      </div>
      <EditServicesModal showModal={showModal} closeModal={closeModal} title="Edit Service Offered"/>
    </div>
  );
};

export default ServiceOffered;
