import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '../../../../../../utils/routes/AuthRoutes';
import { APP_ROUTES } from '../../../../../../utils/routes/AppRoutes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import cross from "../../../../../../assets/images/cross.svg";
import group from "../../../../../../assets/images/Group1686558045.png";

interface Props {
  show: boolean;
  onHide: () => void;
}

const SaveAsDraftAuthErrorDialog: FC<Props> = ({ show, onHide }) => {
const navigate = useNavigate();
const { activeStep } = useSelector((state: RootState) => state.addContract);

const navigateToLoginPage = () => {
  navigate(
    `${AUTH_ROUTES.LOGIN}?redirectTo=${APP_ROUTES.ADD_HOURLY_CONTRACT}&activeStep=${activeStep}`
  );
};

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        backdrop="static"
        onHide={onHide}
        keyboard={false}
        centered
        className="modalsavedraft"
      >
  
        <Modal.Body >
          <div className="text-end pt-2 pe-2" onClick={onHide}>
            <i><img src={cross} alt='' /></i>
            </div>
            <div className="text-center col-lg-7 mx-auto">
                <img src={group} alt="" />
                <h5 className="font-28 fw-semibold mb-0 mt-4" >
                Log In to Save Your Contract!
                </h5>
                <p className='py-3 px-3 mb-0'>
                  Access your account to secure and manage your draft with ease. Easily track changes, collaborate with your team, and finalize your contract whenever you're ready.
                </p>
            </div>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: "center", padding: "30px 0"}} >
          <div className='mx-2'>
            <button className="btn border btn-light rounded-pill primaryBtn submitBtn m-0 px-4" onClick={navigateToLoginPage}>
              Create an Account
            </button>
            </div>
            <div className='mx-2'>
            <button type="button" onClick={navigateToLoginPage} className="btn border btn-light rounded-pill primaryBtn submitBtn m-0 px-4">
              Sign In
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SaveAsDraftAuthErrorDialog;
