import React, { useCallback, useEffect, useMemo, useState } from "react";
// import GoogleMapReact from "google-map-react";
// import styles from "../../common/css/styles/style.module.css";
import styles from "../../common/css/styles/style.module.css"
import { Modal, Button } from "react-bootstrap";
import { GoogleMap, Marker, useJsApiLoader,InfoWindow } from "@react-google-maps/api";
import { JobResponse } from "../interface";
const containerStyle = {
  width: '100%',
  height: '100vh',
}; 
const center = {
  lat: 37.7749, 
  lng: -122.4194, 
};
interface GoogleMapsProps {
  users: JobResponse[];
  selectedUser: JobResponse | null; // Selected user prop
  onSelectUser: (user: JobResponse) => void;
}
const GoogleMaps = ({ users, onSelectUser ,selectedUser}: GoogleMapsProps) => {
  // const googleMapKey = process.env.GOOGLE_MAPS_API_KEY;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDe7heWyITW2XTLJcjgd93gcqL6o80ihVo", // Replace with your API key
    
  });

 
  const [selectedMarker, setSelectedMarker] = useState<any | null>(null); // Store the clicked user
 
  const onLoad = useCallback(
    (map: any) => {
      const bounds = new window.google.maps.LatLngBounds();
      let hasValidCoordinates = false;
      console.log(`Total number of users: ${users.length}`); // Log number of users

      users.forEach((user) => {
        if (user.location && user.location.coordinates) {
          const [lng, lat] = user.location.coordinates;
          console.log(`User: ${user._id}, Coordinates: Latitude: ${lat}, Longitude: ${lng}`);
          bounds.extend({ lat, lng });
          hasValidCoordinates = true;
        }else {
          console.log(`User: ${user._id} does not have valid coordinates.`);
        }
      });

      if (hasValidCoordinates) {
        map.fitBounds(bounds); // Fit map bounds to show all markers
      } else {
        map.setCenter(center); // Fallback to default center
      }
      // if (users.length > 0 && users[0].location && users[0].location.coordinates) {
      //   setSelectedMarker(users[0]); // Highlight first marker
      // }
    },
    [users]
  );
  useEffect(() => {
    if (selectedUser && selectedUser.location && selectedUser.location.coordinates) {
      const [lng, lat] = selectedUser.location.coordinates;
      // setSelectedMarker(selectedUser);
      // Optionally: you can center the map on the selected marker using `map.panTo({ lat, lng })`
    }
  }, [selectedUser]);
  const handleMarkerClick = (user: any) => {
    // alert("jsdflksfkl")
    console.log(`Selected user ID: ${user._id}`);
    setSelectedMarker(user); // Set the selected user to open the info window
    // onSelectUser(user); // Set the selected user
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarker(null); // Clear the selected user when the info window is closed
  };

  
  if (!isLoaded) {
    return <div>Loading...</div>; // Show loading while the map is loading
  }


  return (
    <div className="col-lg-8">
      <div className={styles.RightOpportunity}>
        <div className={styles.ContarctOuter}>
        <div style={{ height: "100vh", width: "100%" }}>
        
      <GoogleMap
              mapContainerStyle={containerStyle}
             
              onLoad={onLoad}
              center={center}
              zoom={10}
            >
              {/* Place markers for each user */}
              {users.map((user) => {
                const coordinates = user?.location?.coordinates;
                if (coordinates && coordinates.length === 2) {
                  const [lng, lat] = coordinates;
                  return (
                    <Marker
                      key={user._id}
                      position={{ lat, lng }}
                      onClick={() => handleMarkerClick(user)}
                      icon={selectedUser?._id === user._id ? "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" : undefined} // Highlight selected user marker
                    />
                  );
                }
                return null;
              })}

         
         {selectedMarker && (
              <InfoWindow
                position={{
                  lat: selectedMarker?.location?.coordinates[1], // Latitude
                  lng: selectedMarker?.location?.coordinates[0], // Longitude
                }}
                onCloseClick={handleCloseInfoWindow}
              >
                <div>
                  <h6>{selectedMarker.workType}</h6>
                  <p><strong>Latitude:</strong> {selectedMarker.location.coordinates[1]}</p>
                  <p><strong>Longitude:</strong> {selectedMarker.location.coordinates[0]}</p>
                  <p><strong>Description:</strong> {selectedMarker.description}</p>
                  <p><strong>Address</strong> {selectedMarker.address}</p>
                </div>
              </InfoWindow>
            )}
      </GoogleMap>
    
      
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMaps;