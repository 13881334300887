import React from "react";
import { Dropdown, FormControl, ModalBody, ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import security from "../../../assets/images/security.svg";
import blackarrow from "../../../assets/images/blackarrow.svg";
import whitearrow from "../../../assets/images/whitearrow.svg";
import upload from "../../../assets/images/upload.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "../styles/style.module.css";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
}

const EditW9FormModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
}) => {
  return (
    <>
      <Modal show={showModal} onHide={closeModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form>
        <ModalBody>
          <div className="addModalPro">
            
              <div className="row">
              <div className="col-12 mb-3">
                  <div className="bg-lightblue row align-items-center justify-content-center p-3 mx-0 rounded-2">
                    <div className="col-1 text-center">
                      <i><img src={security} alt="" className="img-fluid" /></i>
                    </div>
                    <div className="col-11">
                  <p className="text-14 fw-medium text-black mb-0">Please provide your financial information securely to facilitate payments. Your security is extremely important to us, and only Automate America Financial Administration will have access to this information.</p>
                  </div>
                  </div>
                </div>
                
                
                <div className="col-12">
                  <div className="formGroup">
                    <label>W9 Form</label>
                    <div
                      className={styles.uploadimagefile}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                        // onChange={({ target }) =>
                        //   handleFileUpload(target.files)
                        // }
                      />
                      <div className={styles.uploadimage}>
                        <i>
                          <img src={upload} alt="" />
                        </i>
                        <p className="mb-0 text-14 text-blue">
                          Upload jpeg/png file
                        </p>
                      </div>
                    </div>
                    {/* {img && (
                      <li>
                        <div className={styles.uploadImages}>
                          <img src={img} alt="" className="img-fluid" />
                          <i
                            className="fas fa-times"
                            onClick={removeUploadedFiles} // Call the function to remove the image
                          ></i>
                        </div>
                      </li>
                    )} */}
                  </div>
                </div>
                <div className="col-12">
                  <div className="formGroup">
                    <label>W-8BEN</label>
                    <div
                      className={styles.uploadimagefile}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                        // onChange={({ target }) =>
                        //   handleFileUpload(target.files)
                        // }
                      />
                      <div className={styles.uploadimage}>
                        <i>
                          <img src={upload} alt="" />
                        </i>
                        <p className="mb-0 text-14 text-blue">
                          Upload jpeg/png file
                        </p>
                      </div>
                    </div>
                    {/* {img && (
                      <li>
                        <div className={styles.uploadImages}>
                          <img src={img} alt="" className="img-fluid" />
                          <i
                            className="fas fa-times"
                            onClick={removeUploadedFiles} // Call the function to remove the image
                          ></i>
                        </div>
                      </li>
                    )} */}
                  </div>
                </div>
                <div className="col-12">
                  <div className="formGroup">
                    <label> W-8BEN-E Form</label>
                    <div
                      className={styles.uploadimagefile}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                        // onChange={({ target }) =>
                        //   handleFileUpload(target.files)
                        // }
                      />
                      <div className={styles.uploadimage}>
                        <i>
                          <img src={upload} alt="" />
                        </i>
                        <p className="mb-0 text-14 text-blue">
                          Upload jpeg/png file
                        </p>
                      </div>
                    </div>
                    {/* {img && (
                      <li>
                        <div className={styles.uploadImages}>
                          <img src={img} alt="" className="img-fluid" />
                          <i
                            className="fas fa-times"
                            onClick={removeUploadedFiles} // Call the function to remove the image
                          ></i>
                        </div>
                      </li>
                    )} */}
                  </div>
                </div>
              
              </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto">
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-lg-4 p-3">
              <img
                src={blackarrow}
                alt=""
                width={15}
                height={15}
                style={{ transform: "rotate(-180deg)" }}
              />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto">
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Save
            </span>
            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-lg-4 p-3">
              <img
                src={whitearrow}
                alt=""
                width={15}
                height={15}
              />
            </i>
          </Button>
        </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default EditW9FormModal;
