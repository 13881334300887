import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, Modal, ModalFooter } from "react-bootstrap";
import penbl from "../../../assets/images/penbl.png";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import thumb from "../../../assets/images/profile_picture.png";
import blackarrow from "../../../assets/images/blackarrow.svg";
import whitearrow from "../../../assets/images/whitearrow.svg";
import { getMasterTypes } from "../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../utils/enums/masterTypes.enum";
import { IOption } from "../../../Interfaces";
import Select, { ActionMeta, MultiValue } from "react-select";
import { updateUserProfile } from "../../home/api";
import { OccupationDetails, ProfessInfo } from "../interface";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  primaryOccuDetails: OccupationDetails[] | undefined;
  secondaryOccuDetails: OccupationDetails[] | undefined;
  refresh: () => void;
}

const ProfessionalInfoModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  primaryOccuDetails = [],
  secondaryOccuDetails = [],
  refresh
}) => {
  if (!isOpen) return null;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [primary, setPrimary] = useState("");
  const [primaryId, setPrimaryId] = useState("");
  const [primaryData, setPrimaryData] = useState<IOption[]>([]);
  
  const [secondaryId, setSecondaryId] = useState("");
  const [secondaryData, setSecondaryData] = useState<IOption[]>([]);
  const [value, setValue] = useState<IOption[]>([]);

  useEffect(() => {
    if (primaryOccuDetails && primaryOccuDetails.length > 0) {
      setPrimary(primaryOccuDetails[0].name);
      setPrimaryId(primaryOccuDetails[0]._id);
    } else {
      setPrimary("");
      setPrimaryId("");
    }
    if (secondaryOccuDetails && secondaryOccuDetails.length > 0) {
      const initialValue = secondaryOccuDetails.map(detail => ({
        label: detail.name,
        value: detail._id,
      }));
      setValue(initialValue);
    }
  }, [primaryOccuDetails, secondaryOccuDetails]);

  const loadDropDowns = async () => {
    try {
      const occupationDataRes = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
      if (occupationDataRes?.data) {
        setPrimaryData(
          occupationDataRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setSecondaryData(
          occupationDataRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to load school data", error);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleSubmitCustomOccupation = async () => {
    // const payload = {
    //   type: MASTER_TYPE.OCCUPATIONS,
    //   name: searchTerm,
    //   alias: searchTerm,
    // };
    // toast.promise(postMasterType(payload), {
    //   pending: {
    //     render() {
    //       return MESSAGES.POSTING_CONTRACTS.VERIFYING;
    //     },
    //   },
    //   success: {
    //     render(newOccupation) {
    //       formik.setFieldValue(
    //         "primaryOccupation",
    //         newOccupation.data.data.name
    //       );
    //       setFilterValue("");
    //       setSearchTerm("");
    //       dispatch(
    //         setOccupation({
    //           ...occupation,
    //           primaryOccupation: {
    //             label: newOccupation.data.data.name,
    //             value: newOccupation.data.data._id,
    //           },
    //         })
    //       );
    //       return MESSAGES.POSTING_CONTRACTS.ADDING;
    //     },
    //   },
    //   error: {
    //     render(error) {
    //       let errorMesage = error as APIResponseError;
    //       return errorMesage?.data?.data?.message;
    //     },
    //   },
    // });
  };

  const handleOccupationChange = (label: string, value: string) => {
    setPrimary(label);
    setPrimaryId(value);
  };

  const updateOccupation = async () => {
    if (primaryId || secondaryId) {
      const selectedSecondaryIds = value.map(option => option.value); 
      console.log(selectedSecondaryIds, "selectedSecondaryIds");
      const payload = {
        occupationIds: [primaryId],
        secondaryOccupationIds: selectedSecondaryIds,
      };
      try {
        await updateUserProfile(payload);
        onClose();
      } catch (error) {
        console.log("Error occuring while updating the occupations", error);
      }
    }
  };

  const handleSelectChange = (
    newValue: MultiValue<IOption>,
    actionMeta: ActionMeta<IOption>
  ) => {
    const selectedOptions = Array.isArray(newValue) ? newValue : [];
    if (selectedOptions.length <= 5) {
      setValue(selectedOptions); 
    } else {
      alert("You can only select up to 5 occupations.");
    }
  };

  const primaryOccOptions =
    filterValue && filterValue !== ""
      ? primaryData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : primaryData;
      
  return (
    // <div className="modal" style={{ display: "block" }}>
    //   <div className="modal-dialog addModal educationModal">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title">{title}</h5>
    //         <button
    //           type="button"
    //           className="btn-close"
    //           data-bs-dismiss="modal"
    //           aria-label="Close"
    //           onClick={onClose}
    //         ></button>
    //       </div>
    //       <div className="modal-body">
    //         <div className="addModalPro">
    //           <form>
    //             <div className="formGroup">
    //               <label>Primary Occupation*</label>
    //               <div style={{ marginBottom: "30px" }}>
    //                 <Dropdown>
    //                   <Dropdown.Toggle variant="primary" id="dropdown-basic">
    //                     {primary || "Please select an option"}
    //                   </Dropdown.Toggle>

    //                   <Dropdown.Menu>
    //                     <div className="">
    //                       <FormControl
    //                         autoFocus
    //                         className="mx-3 my-2 w-auto"
    //                         placeholder="Search..."
    //                         onChange={(e) => setFilterValue(e.target.value)}
    //                         value={filterValue}
    //                       />
    //                     </div>

    //                     <div
    //                       style={{
    //                         maxHeight: "300px",
    //                         // overflowY: "scroll",
    //                       }}
    //                     >
    //                       {primaryOccOptions.length === 0 ? (
    //                         <Dropdown.Item disabled>
    //                           No options found
    //                         </Dropdown.Item>
    //                       ) : (
    //                         [
    //                           {
    //                             label: "Select primary occupation",
    //                             value: "",
    //                           },
    //                           ...primaryOccOptions,
    //                         ].map((option, index: number) => (
    //                           <>
    //                             <Dropdown.Item
    //                               key={index}
    //                               onClick={() =>
    //                                 handleOccupationChange(
    //                                   option.label,
    //                                   option.value
    //                                 )
    //                               }
    //                             >
    //                               {option.label}
    //                             </Dropdown.Item>
    //                           </>
    //                         ))
    //                       )}

    //                       <div className={styles.addOccuatin}>
    //                         <div className="col-8">
    //                           <input
    //                             type="text"
    //                             placeholder="Type an occupation here you want to add..."
    //                             name="customOccupation"
    //                             value={searchTerm}
    //                             onChange={(e) => setSearchTerm(e.target.value)}
    //                             onKeyDown={(e) => {
    //                               if (e.key === "Enter") {
    //                                 handleSubmitCustomOccupation();
    //                                 e.preventDefault();
    //                               }
    //                             }}
    //                           />
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </Dropdown.Menu>
    //                 </Dropdown>
    //               </div>
    //             </div>
    //             <div className="formGroup">
    //               <label>Other Occupations</label>
    //               <div>
    //                 <Select
    //                   isMulti
    //                   name="colors"
    //                   options={secondaryData} 
    //                   className="basic-multi-select"
    //                   classNamePrefix="select"
    //                   onChange={handleSelectChange} 
    //                   value={value}
    //                 />
    //               </div>
    //             </div>
    //             <div className="col-12" style={{ marginBottom: "30px" }}>
    //               <p className="text-blue text-14">
    //                 Note :You can add up to 5 additional occupations. This
    //                 showcases your versatility and breadth of skills
    //               </p>
    //             </div>

    //             <div className="formGroup">
    //               <button type="button" onClick={updateOccupation}>
    //                 Save & Close ›
    //               </button>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
    <Modal show={isOpen} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>       
        <div className="addModalPro">
              <form>
                <div className="formGroup">
                  <label>Primary Occupation*</label>
                  <div style={{ marginBottom: "30px" }}>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {primary || "Please select an option"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="">
                          <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Search..."
                            onChange={(e) => setFilterValue(e.target.value)}
                            value={filterValue}
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: "300px",
                            // overflowY: "scroll",
                          }}
                        >
                          {primaryOccOptions.length === 0 ? (
                            <Dropdown.Item disabled>
                              No options found
                            </Dropdown.Item>
                          ) : (
                            [
                              {
                                label: "Select primary occupation",
                                value: "",
                              },
                              ...primaryOccOptions,
                            ].map((option, index: number) => (
                              <>
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    handleOccupationChange(
                                      option.label,
                                      option.value
                                    )
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              </>
                            ))
                          )}

                          <div className={styles.addOccuatin}>
                            <div className="col-8">
                              <input
                                type="text"
                                placeholder="Type an occupation here you want to add..."
                                name="customOccupation"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSubmitCustomOccupation();
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="formGroup">
                  <label>Other Occupations</label>
                  <div>
                    <Select
                      isMulti
                      name="colors"
                      options={secondaryData} 
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSelectChange} 
                      value={value}
                    />
                  </div>
                </div>
                <div className="col-12" style={{ marginBottom: "30px" }}>
                  <p className="text-blue text-14">
                    Note :You can add up to 5 additional occupations. This
                    showcases your versatility and breadth of skills
                  </p>
                </div>

                {/* <div className="formGroup">
                  <button type="button" onClick={updateOccupation}>
                    Save & Close ›
                  </button>
                </div> */}
              </form>
            </div>      
        </Modal.Body>
        <ModalFooter>
          <div className="btn-hover-effect">
            <h6 onClick={onClose} className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
                              >
              <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                Back
              </span>
              <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-lg-4 p-3">
                <img src={blackarrow} alt="" width={15} height={15} style={{transform: "rotate(-180deg)"}}/>
              </i>
            </h6>
          </div>
          <div className="btn-hover-effect">
            <h6 onClick={updateOccupation} className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto">
              <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                Save
              </span>
              <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-lg-4 p-3">
                <img src={whitearrow} alt="" width={15} height={15}/>
              </i>
            </h6>
          </div>
          
        </ModalFooter>
      </form>
      
      </Modal>
    </>
  );
};

export default ProfessionalInfoModal;
