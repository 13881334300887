import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../css/styles/style.module.css";
import info from "../../../../assets/images/info.png";
import timeling from "../../../../assets/images/timeiing.png";
import prpbag from "../../../../assets/images/prpbag.png";
import loc from "../../../../assets/images/loc.png";
import Slider from "react-slick";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { CONTRACT_Type } from "../../../../utils/enums/types";
import { TYPE, WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import moment from "moment";
import { getDirectJob, getOpenContractList } from "../api";
import BoostContract from "../../../../shared/CustomModal/BoostContractModal";
import CustomModal from "../../../../shared/CustomModal/customModal";
import { DirectJobResponse } from "../interface/directjobInterface";
import tip from "../../../../../src/assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import Toltip from "../../toltip/page";
const DirectJob = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [directJob, setDirectJob] = useState<DirectJobResponse[]>([]);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [workId, setWorkId] = useState("");

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2.02,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.02,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleShowContractDetails = (id: string) => {
    navigate(`${APP_ROUTES.OPENCONTRACT_DETAILS}/${id}`);
  };

  useEffect(() => {
    getOpenContractsForMe();
  }, []);

  const getOpenContractsForMe = async () => {
    const response: any = await getDirectJob(
      CONTRACT_Type.DIRECT_JOB,
      TYPE.DONEFORME
    );
    setDirectJob(response?.data);
    setLoading(false);
  };

  const handleBoostContract = (id: string) => {
    setWorkId(id);
    setModalDetail({
      show: true,
      title: "",
      flag: "boostContract",
    });
  };

  return (
    <>
      {directJob?.length > 0 && (
        <div className="Contracts">
          <div className="container">
            <div className={styles.ContractTabs}>
              <div className={styles.AllContract}>
                <div className="row align-items-center justify-content-between mx-0 mt-4 w-100">
                  <div className="col-lg-6 col-12">
                    <div className="topTipHover">
                      <div className="d-flex align-items-center">
                        <h4 className="form-check-label text-20 fw-bold  font-inter">
                          Direct Jobs
                        </h4>
                        <i>
                          <img className="customeTolImg" src={tip} alt="" />
                        </i>
                        <Toltip
                          slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                          labelId={CONSTANT_MANAGER.HOME_DATA.MY_TEAMS}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div
                      className={styles.RightFilyter}
                      style={{ textAlign: "end" }}
                    >
                      <div className={styles.FormGroup}>
                        <span>Filter:</span>
                        <select>
                          <option>Date and time</option>
                        </select>
                      </div>
                      <div className={styles.FormGroup}>
                        <span>Sort:</span>
                        <select>
                          <option>This Week</option>
                        </select>
                      </div>
                      <button onClick={() => navigate("/add-contract")}>
                        +Post New Contract
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {loading ? (
                <div className="spinner-grow text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div className={styles.OuterSlides}>
                  <Slider {...settings}>
                    {directJob?.length > 0
                      ? directJob?.map((data: any, idx: number) => {
                        return (
                          <div key={idx} className={styles.ContarctOuter}>
                            <div className={styles.ContarctHead}>
                              <div className="row align-item-center justify-content-between mx-0 w-100">
                                <div className="col-xl-5 col-lg-12 col-md-5 col-12">
                                  <div className={styles.leftContract}>
                                    <h5>Contract ID : #{data?.workNumber}</h5>
                                    <span>
                                      Start Date:{" "}
                                      {moment(data.times.startDate).format(
                                        "DD MMM YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-7 col-lg-12 col-md-7 col-12">
                                  <div
                                    className={`${styles.RightContract} ${styles.leftContract}`}
                                    style={{ justifyContent: "flex-end" }}
                                  >
                                    <button className={styles.CoptText}>
                                      <i className="far fa-copy"></i> Copy
                                    </button>
                                    <button>
                                      {data?.workStatus === "draft"
                                        ? "Draft"
                                        : data?.workStatus === "pending"
                                          ? "Pending"
                                          : data.workStatus === "approved"
                                            ? "Approved"
                                            : ""}
                                    </button>
                                    {/* <span className={styles.switchBtn}>
                                                        <div className="form-check form-switch">
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Unpublish</label>
                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />

                                                        </div>
                                                    </span> */}
                                    <button
                                      onClick={() =>
                                        handleShowContractDetails(data._id)
                                      }
                                    >
                                      View Work
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.outerNameDetail}>
                              <div className="row">
                                <div className={styles.OpenContout}>
                                  <div className={styles.OpenContactData}>
                                    <div className={styles.adrssLoc}>
                                      <p>
                                        <img src={loc} alt="" />{" "}
                                        {data?.address}
                                      </p>
                                      {/* <span className={`${styles.standardContact} ${styles.contarctBag}`}><img src={prpbag} alt="" />{data.planType === "whiteglove" ? "White Glove Contract" : "Standard Contract"}</span> */}
                                    </div>
                                    <p>
                                      {" "}
                                      <img src={timeling} alt="" />
                                      Initiated
                                      {moment(data.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                    {/* <h6>{data?.description}</h6> */}
                                    <div
                                      className={styles.desc}
                                      dangerouslySetInnerHTML={{
                                        __html: data.description,
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className={styles.RecevideContact}>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div
                                        className={styles.InnrRecveContarct}
                                      >
                                        <h5>Shortlisted Applicants</h5>
                                        <span>
                                          {data?.shortlistedApplication
                                            ? data?.shortlistedApplication
                                            : 0}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div
                                        className={styles.InnrRecveContarct}
                                      >
                                        <h5>Applications Received</h5>
                                        <span>
                                          {data?.receivedApplication
                                            ? data?.receivedApplication
                                            : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div
                                        className={styles.InnrRecveContarct}
                                      >
                                        <h5>Offer Pending</h5>
                                        <span>
                                          {data?.pendingApplication
                                            ? data?.pendingApplication
                                            : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div
                                        className={`${styles.InnrRecveContarct} ${styles.RedBox}`}
                                      >
                                        <h5>Rejected Applicants</h5>
                                        <span>
                                          {data?.rejectedApplication
                                            ? data?.rejectedApplication
                                            : 0}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {data?.boosts ? <div className={styles.ApplyBTN}>
                                  <button>
                                    Boosted On {moment(data.boosts?.startDate).format("DD-MM-YYYY")}
                                    <p>Renewal {moment(data.boosts?.endDate).format("DD-MM-YYYY")}</p>
                                  </button>
                                </div> :

                                  <div
                                    className={styles.ApplyBTN}
                                    onClick={() => handleBoostContract(data._id)}
                                  >
                                    <button>Boost This Contract</button>
                                  </div>
                                }

                              </div>
                            </div>
                          </div>
                        );
                      })
                      : ""}
                  </Slider>
                </div>
              )}
              {modalDetail.show && (
                <CustomModal
                  show={modalDetail.show}
                  backdrop="static"
                  showCloseBtn={true}
                  isRightSideModal={false}
                  mediumWidth={false}
                  className={
                    modalDetail.flag === "boostContract" ? "boostContract" : ""
                  }
                  child={
                    modalDetail.flag === "boostContract" ? (
                      <BoostContract
                        onCloseModal={() => handleOnCloseModal()}
                        workId={workId}
                      />
                    ) : null
                  }
                  onCloseModal={() => handleOnCloseModal()}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DirectJob;
