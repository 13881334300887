import React, { useState } from "react";
import { Dropdown, FormControl, ModalBody, ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import security from "../../../assets/images/security.svg";
import blackarrow from "../../../assets/images/blackarrow.svg";
import whitearrow from "../../../assets/images/whitearrow.svg";
import styles from "../styles/style.module.css";
import { PayRateInterface } from "../interface";
import { updateUserProfile } from "../../home/api";

const EditFinancialInfoModal: React.FC<PayRateInterface> = ({
  showModal,
  closeModal,
  title,
  rates,
  refresh,
}) => {
  const [baseRate, setBaseRate] = useState<number | undefined>(rates?.baseRate);
  const [overtimeRate, setOvertimeRate] = useState<number | undefined>(rates?.overTimeRate);
  const [doubleTimeRate, setDoubletimeRate] = useState<number | undefined>(rates?.doubleTimeRate);
  const [flatRate, setFlatRate] = useState<number | undefined>(rates?.flatRate);

  const handleUpdateRates = async () => {
    console.log("clicked");
    
    if (baseRate && overtimeRate && doubleTimeRate && flatRate) {
      const payload = {
        rates: {
          baseRate: baseRate,
          overTimeRate: overtimeRate,
          doubleTimeRate: doubleTimeRate,
          flatRate: flatRate,
        },
      };
      try {
        await updateUserProfile(payload);
        refresh();
      } catch (error) {
        console.log("Error occuring while updating the rates", error);
      }
    }else{
      alert("Please enter full details")
    }
  };

  console.log();
  

  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className="addModalPro">
          <form>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="bg-lightblue row align-items-center justify-content-center p-3 mx-0 rounded-2">
                  <div className="col-1 text-center">
                    <i>
                      <img src={security} alt="" className="img-fluid" />
                    </i>
                  </div>
                  <div className="col-11">
                    <p className="text-14 fw-medium text-black mb-0">
                      Please provide your financial information securely to
                      facilitate payments. Your security is extremely important
                      to us, and only Automate America Financial Administration
                      will have access to this information.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Pay Rates</label>
                  <div>
                    <input
                      type="text"
                      value={baseRate}
                      // onChange={(e) => setStartDate(e.target.value)}
                      className="form-control"
                      placeholder="$"
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Base Rate (0-40 hours)</label>
                  <div>
                    <input
                      type="text"
                      value={baseRate}
                      onChange={(e) => setBaseRate(Number(e.target.value))}
                      className="form-control"
                      placeholder="$"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Overtime Rate (41+ hours)</label>
                  <div>
                    <input
                      type="text"
                      value={overtimeRate}
                      onChange={(e) => setOvertimeRate(Number(e.target.value))}
                      className="form-control"
                      placeholder="$"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Sunday and Holiday Rate</label>
                  <div>
                    <input
                      type="text"
                      value={doubleTimeRate}
                      onChange={(e) =>
                        setDoubletimeRate(Number(e.target.value))
                      }
                      className="form-control"
                      placeholder="$"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Flat Rate (All-inclusive, no overtime)</label>
                  <div>
                    <input
                      type="text"
                      value={flatRate}
                      onChange={(e) => setFlatRate(Number(e.target.value))}
                      className="form-control"
                      placeholder="$"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          variant="secondary"
          onClick={closeModal}
          className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            Back
          </span>
          <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-4">
            <img
              src={blackarrow}
              alt=""
              width={15}
              height={15}
              style={{ transform: "rotate(-180deg)" }}
            />
          </i>
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={handleUpdateRates}
          className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            Save
          </span>
          <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-4">
            <img src={whitearrow} alt="" width={15} height={15} />
          </i>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditFinancialInfoModal;
