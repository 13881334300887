import React, { useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import penbl from "../../../assets/images/penbl.png";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import thumb from "../../../assets/images/profile_picture.png";
import { IOption } from "../../../Interfaces";
import { updateUserProfile } from "../../home/api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import whitearrow from "../../../assets/images/whitearrow.svg";
import blackarrow from "../../../assets/images/blackarrow.svg";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  refresh: () => void;
  //   indexNumber: number | null;
  bio: string | undefined;
}

const ShortBioModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  bio,
  refresh,
}) => {
  if (!isOpen) return null;
  const [newBio, setNewBio] = useState(bio);

  const editorConfiguration = {
    toolbar: ["undo", "redo", "|", "bold", "italic"],
    height: "150px",
  };

  const handleBioUpdate = async () => {
    if (bio) {
      const payoad = {
        bio: newBio,
      };

      try {
        await updateUserProfile(payoad);
        console.log(newBio, "new bio");
        
        refresh();
        onClose();
      } catch (error) {
        console.log("Error occuring while updating Bio", error);
      }
    }
  };

  return (
    // <div className="modal" style={{ display: "block" }}>
    //   <div className="modal-dialog addModal educationModal">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title">{title}</h5>
    //         <button
    //           type="button"
    //           className="btn-close"
    //           data-bs-dismiss="modal"
    //           aria-label="Close"
    //           onClick={onClose}
    //         ></button>
    //       </div>
    //       <div className="modal-body">
    //         <div className="addModalPro">
    //           <form>
    //             <div className="row">
    //               <div className="col-12">
    //                 <div className="formGroup">
    //                   <label>Short Bio*</label>
    //                   <div style={{ marginBottom: "30px" }}>
    //                     <CKEditor
    //                       editor={ClassicEditor}
    //                       data={bio}
    //                       config={editorConfiguration}
    //                       onChange={(event, editor) =>
    //                         setNewBio(editor.getData())
    //                       }
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="col-12">
    //                 <div className="formGroup">
    //                   <button type="button" onClick={handleBioUpdate}>
    //                     Save & Close ›
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <Modal show={isOpen} onHide={onClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form>
        <Modal.Body>       
          <div className="row p-3">
            <div className="col-12">
              <div className="formGroup">
                <label className="fw-semibold mb-2">Short Bio*</label>
                <div>
                  <CKEditor
                    editor={ClassicEditor}
                    data={bio}
                    config={editorConfiguration}
                    onChange={(event, editor) =>
                      setNewBio(editor.getData())
                    }
                  />
                </div>
              </div>
            </div>          
          </div>             
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-hover-effect">
          <Button type="button" variant="secondary" onClick={onClose} className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
                            >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-lg-4 p-3">
              <img src={blackarrow} alt="" style={{transform: "rotate(-180deg)"}}/>
            </i>
          </Button>
          </div>
          <div className="btn-hover-effect">
          <h6 onClick={handleBioUpdate} className="btn bg-white arrowblue border-grey border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto">
         
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Save
            </span>
            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-lg-4 p-3">
              <img src={whitearrow} alt=""/>
            </i>
          </h6>
          </div>
        </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ShortBioModal;
