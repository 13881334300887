import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import ProfExpModal from "../modals/ProfExpModal";
import { ProfExperience } from "../interface";

interface ModalProps {
  profData: ProfExperience[];
  refresh: () => void;
}

const ProfessionalExperience: React.FC<ModalProps> = ({
  profData = [],
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<ProfExperience | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);

  const openModal = (data?: ProfExperience, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  const handleEdit = (updatedData: ProfExperience) => {
    // Logic to update the specific education record
    console.log("Updated Education Data:", updatedData);
    // Implement the update logic here (e.g., make an API call)
    refresh(); // Refresh the education list
  };

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <h5 className="text-20 fw-semibold mb-0 ">
            Professional Experience{" "}
          </h5>
        </div>
        {/* <div className="col-2">
          <div className=" text-end">
            <span>
              <img
                src={penbl}
                className="cursor-pointer"
                alt=""
                onClick={() => openModal()}
              />
            </span>
          </div>
        </div> */}
        <div className="col-12 mt-3">
          <h4 className="text-16 fw-medium mb-0">Work Experience </h4>
        </div>
      </div>
      <div>
        {profData?.length > 0
          ? profData?.map((data, ind) => (
              <div
                key={ind}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  padding: "5px",
                }}
              >
                <ul className={styles.experienceSet}>
                  <li>
                    <span className={styles.expUi}></span>
                    <div>
                      <h4>{data?.occupationDetail?.name}</h4>
                      <h5>{data?.companyDetail?.name}</h5>
                      <h6>
                        <span>
                          {new Date(data?.from).toLocaleString("default", {
                            month: "short",
                            year: "numeric",
                          })}{" "}
                          {data?.isCurrent === "true"
                            ? "Present"
                            : new Date(data?.to).toLocaleString("default", {
                                month: "short",
                                year: "numeric",
                              })}{" "}
                          <span>{`${
                            new Date(
                              data?.isCurrent === "true" ? Date.now() : data?.to
                            ).getFullYear() - new Date(data?.from).getFullYear()
                          } yrs ${
                            (new Date(
                              data?.isCurrent === "true" ? Date.now() : data?.to
                            ).getMonth() -
                              new Date(data?.from).getMonth() +
                              12) %
                            12
                          } mos`}</span>
                        </span>
                      </h6>
                      <h6>Responsibilites</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.description ? data?.description : "",
                        }}
                      />
                    </div>
                  </li>
                </ul>
                <i>
                <img
                  src={penbl}
                  alt=""
                  className="cursor-pointer ms-auto"
                  style={{width: "15px", height: "15px"}}
                  onClick={
                    // () => handleEdit(data)
                    () => openModal(data, ind)
                  }
                />
                </i>
              </div>
            ))
          : "You haven't added any Professional Experience yet!"}
        <li>
          <h4
            className="text-14 fw-semibold text-blue cursor-pointer"
            onClick={() => openModal()}
          >
            + Add more
          </h4>
        </li>
      </div>
      <ProfExpModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit Professional Experience"
        profExpData={profData}
        refresh={refresh}
        handleEdit={handleEdit}
        initialData={editData}
        indexNumber={indexNumber}
      />
    </div>
  );
};

export default ProfessionalExperience;
