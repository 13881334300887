import React, { useEffect, useState } from "react";
import styles from "../styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import left from "../../../../assets/images/left.png";
import { dropdownValidationSchema } from "../validations";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setCitizenship } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import { getCitizenshipList } from "../api";
import { Dropdown, FormControl } from "react-bootstrap";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import { RootState } from "../../../../redux/store";

const Citizenship: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [citizenship, setCitizenshipList] = useState([]);
  const userDetail = useSelector((state: RootState) => state.userData);


  const formik = useFormik({
    initialValues: {
      selectedCitizenship: userDetail?.citizenshipData?.citizenship || "",  
      selectedCitizenshipName: userDetail?.citizenshipData?.countyName || "",
    },
    validationSchema: dropdownValidationSchema,
    onSubmit: (values) => {
      const selectedOptionData: any = {
        citizenship: values.selectedCitizenship, 
        countyName: values.selectedCitizenshipName
      };
      dispatch(setCitizenship(selectedOptionData));
      navigate(AUTH_ROUTES.ABOUT_US);
    },
  });
  

  const getCitizenshipData = async () => {
    try {
      const response: any = await getCitizenshipList();
      setCitizenshipList(response?.data || []);
    } catch (error) {
      console.error('Error fetching citizenship data:', error);
    }
  };

  useEffect(() => {
    getCitizenshipData();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = (citizenship ?? []).filter(
    (option: { name: string }) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDropdownSelect = (option: { _id: string; name: string }) => {
    formik.setFieldValue("selectedCitizenship", [option._id]);  // Ensure this is an array
    formik.setFieldValue("selectedCitizenshipName", option.name);
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.occupationdetails}>
          <div className={styles.signuppage}>
            <div className={styles.signUPContainer} style={{ padding: "15px" }}>
              <form onSubmit={formik.handleSubmit}>
                <h5>Citizenship</h5>
                <span className={styles.Leftntm}>
                  <a href="#">
                    <img src={left} alt="" onClick={() => navigate(-1)} />
                  </a>
                </span>
                <p>Please enter the country of your citizenship</p>
                <div className={styles.fromGroup}>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {formik.values.selectedCitizenshipName || "Please select an option"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className={styles.searchinputteam}>
                        <FormControl
                          autoFocus
                          className="position-absolute left-0 right-0"
                          placeholder="Search..."
                          style={{ width: "calc(100% - 30px) !important;" }}
                          onChange={handleSearch}
                          value={searchTerm}
                        />
                      </div>
                      <div className={styles.droptopsearch}>
                        {filteredOptions.length === 0 && (
                          <Dropdown.Item disabled>No options found</Dropdown.Item>
                        )}
                        {[{ name: "Select Citizenship", _id: "" }, ...filteredOptions]?.map((option, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleDropdownSelect(option)}
                          >
                            {option.name}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  {formik.touched.selectedCitizenship && formik.errors.selectedCitizenship && (
                    <div className={styles.error}>
                      {formik.errors.selectedCitizenship}
                    </div>
                  )}
                </div>
                <div className="col-lg-3 col-6 mx-auto">
                  <div className="text-center my-3 btn-hover-effect border rounded-pill">
                    <button
                      type="submit"
                      className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 w-100 pe-5 text-center"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                      <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Citizenship;
