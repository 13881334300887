import React, { useEffect, useState } from "react";
import { JobResponse, Pagination } from "../interface";
import styles from "../../common/css/styles/style.module.css";
import loc from "../../../assets/images/loc.png";
import bags2 from "../../../assets/images/bags2.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { getRecommendedWork } from "../api";
import moment from "moment";
import EligibiltyModal from "../../common/modals/eligibilty/page";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";

interface UserListProps {
  users: JobResponse[];

  onSelectUser: (user: JobResponse) => void;
  fetchMoreData: () => void;
}

const UserList: React.FC<UserListProps> = ({
  users,
  onSelectUser,
  fetchMoreData,
}) => {
  const navigate = useNavigate();
  // const hasMoreItems = pagination.currentPage < pagination.totalPage;
  const hasMoreItem = true;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");
  const handleShowModal = (value: any) => {
    if (value.eligibilityQuestions.length > 0 || value.interviewQuestions.length > 0) {
      setShowModal(true);
      setWorkId(value._id);
    } else {
      navigate(`${APP_ROUTES.JOBDETAIL}/${value._id}`);
    }
    
  };
  return (
    <>
    {showModal && (
        <EligibiltyModal onClose={() => setShowModal(false)} workId={workId} />
      )}
    <div className="col-lg-4">
      <InfiniteScroll
        style={{
          // display: 'flex',
          // flexDirection: 'column-reverse',
          overflowY: "scroll", // Enable vertical scrolling
          scrollbarWidth: "none", // Hide scrollbar for Firefox
          msOverflowStyle: "none", // Hide scrollbar for IE and Edge
        }}
        dataLength={users?.length || 0}
        next={fetchMoreData}
        height={700}
        hasMore={hasMoreItem}
        loader
      >
        {users.map((user, index) => (
          <div
            key={index}
            className={styles.ContarctOuter}
            onClick={() => onSelectUser(user)}
            style={{ cursor: "pointer" }}
          >
            <div className={styles.opprBox}>
              <div className={styles.PendingCOnatctList}>
                <h6>{user?.occupation?.name}</h6>
                <div className={styles.Loca}>
                  <p className={styles.Locate}>
                    <img src={loc} alt="" /> {user?.address}
                  </p>
                </div>
                <p>
                  <span className={`${styles.Hrrate} ${styles.HrrateRed}`} style={{marginBottom: "0"}}>
                    {" "}
                    <img src={bags2} alt="" />
                    {user?.planType}
                  </span>
                </p>
                <div className={styles.PenigSkil}>
                  <ul>
                    <li>
                      <h6>Start Date</h6>
                      <p>
                        {moment(user?.times?.startDate).format("MMM DD, YYYY")}
                      </p>
                    </li>
                    <li>
                      <h6>Duration</h6>
                      <p>
                        {moment(user?.times?.plannedHoursPerDay).format(
                          "MMM DD, YYYY"
                        )}
                      </p>
                    </li>
                    <li>
                      <h6>Estimated End Date</h6>
                      <p>
                        {moment(user?.times?.endDate).format("MMM DD, YYYY")}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className={styles.PenigSkilRate}>
                  <h5>Pay Rates</h5>
                  <ul>
                    <li>
                      <span>Base</span>
                      <label>{user?.rates?.baseRate}</label>
                    </li>
                    <li>
                      <span>Overtime</span>
                      <label>{user?.rates?.overTimeRate}</label>
                    </li>
                    <li>
                      <span>Sun/Holidays</span>
                      <label>{user?.rates?.doubleTimeRate}</label>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <div className={styles.bordeerBottom}></div>
                  <div className={styles.flexbtns}>
                    <button
                      type="button"
                      className={`btn ${styles.commonbtn} ${styles.applybtn}`}
                      onClick={() => handleShowModal(user)}
                    >
                      Apply now ›
                    </button>
                    <button
                      type="button"
                      className={`btn ${styles.commonbtn} ${styles.sharebtn}`}
                    >
                      Share{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
    </>
  );
};

export default UserList;