import React, { useState } from "react";
import styles from "../styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import left from "../../../../assets/images/left.png";
import { useFormik } from "formik";
import { passwordSchema } from "../validations";
import { useDispatch, useSelector } from "react-redux";
import { setPassword } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import eye from "../../../../assets/images/eye.png";
import closeye from "../../../../assets/images/closeye.png";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import { RootState } from "../../../../redux/store";

const Password: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state: RootState) => state.userData);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      password: userDetail ? userDetail?.password?.password : "",
    },
    validationSchema: passwordSchema,
    onSubmit: (values) => {
      const password = {
        password: values.password,
      };
      dispatch(setPassword(password));
      navigate(AUTH_ROUTES.OCCUPATION);
    },
  });

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>

          <div className={styles.signUPContainer} style={{ padding: "15px" }}>
            <form onSubmit={formik.handleSubmit}>
              <h5>Enter Password</h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(-1)} />
                </a>
              </span>
              <p>Enter your Password </p>

              <div className={styles.fromGroup}>
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Set your Password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && (
                  <div className={styles.error}>{formik.errors.password}</div>
                )}
                <span onClick={togglePasswordVisibility}>
                  {passwordShown ? (
                    <img src={eye} alt="" />
                  ) : (
                    <img src={closeye} alt="" />
                  )}
                </span>
              </div>


              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-pill">

                  <button
                    type="submit"
                    className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                    <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Password;
