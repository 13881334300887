import React, { useEffect, useRef, useState } from "react";
import "react-markdown-editor-lite/lib/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setEligibilityQuestions,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import styles from "../../styles/styles.module.css";
import TextEditorCustomComp from "../StepsComonents/TextEditorCustomComp/TextEditorCustomComp";

const EligibilityQuestions: React.FC = () => {
  const dispatch = useDispatch();
  const { eligibilityQuestions } = useSelector(
    (state: RootState) => state.addContract
  );
  const [ques, setQues] = useState<any>(eligibilityQuestions);
  const [index, setIndex] = useState<number>(0);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    onValueChange(ques, index);
  }, [ques]);

  const formIk: any = useFormik({
    initialValues: {
      eligibilityQuestions,
    },
    onSubmit: () => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
    },
  });

  const addNewQuestion = (): void => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.push("");
    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const onValueChange = (value: string, index: number): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the previous timeout
    }

    // Set a new timeout
    timeoutRef.current = window.setTimeout(() => {
      const updatedQuestions = [...eligibilityQuestions];
      updatedQuestions[index] = value;

      dispatch(setEligibilityQuestions(updatedQuestions));
      formIk.setFieldValue("eligibilityQuestions", updatedQuestions);
    }, 300); // Adjust the delay as needed (e.g., 300ms)
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.splice(index, 1);

    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };
  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_MANUFACTURER));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
  };
  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">

            <div className={styles.registerOuter}>
              <form onSubmit={formIk.handleSubmit}>
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row">
                      <div className="col-md-6">
                      <div className={styles.registerLeft}>
                          <h4 className="text-white text-32 fw-semibold">
                            Eligibility Questions
                          </h4>
                          <p className="text-16 text-white opacity-75">
                            To ensure candidates meet essential job
                            requirements and legal standards, Automate
                            America Company uses the following yes or no
                            eligibility questions.
                          </p>
                        
                        <div
                        >
                          {/* <p>Add a Legal information of the work that you need</p> */}
                          <figure>
                            <img
                              src={Occupations}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-6">
                        <div className={styles.signUPContainers}>
                          <div className={styles.registerRight}>
                            
                            <label >
                              Create Eligibility Questions
                            </label>
                            <div className={styles.ScrollDiv}>
                            {eligibilityQuestions?.map((x, i: number) => (
                              <>
                                <div
                                  className={styles.fromGroup}
                                  style={{ maxWidth: "100%" }}
                                >
                                  <div className={styles.AddBtns}>
                                    <label>
                                      Question{i + 1}:
                                    </label>
                                    <div className={styles.AddBtn}>
                                      <div className="row align-items-center justify-content-between">
                                        <div className="col-11">
                                          {/* <textarea
                                            // type="text"
                                            placeholder="Write your question here"
                                            className="form-control"
                                            style={{ minHeight: "150px" }}
                                            value={x}
                                            onChange={(e) =>
                                              onValueChange(e.target.value, i)
                                            }
                                          /> */}
                                        <TextEditorCustomComp data={x} setDesc={setQues} setIndex={setIndex} index={i}/>

                                        </div>
                                        <div className="col-1 px-0">
                                          {(i === eligibilityQuestions.length - 1 && eligibilityQuestions.length < 5) && (
                                            <button
                                              type="button"
                                              className="btn btn-primary py-2 my-1 rounded-circle text-20"
                                              onClick={addNewQuestion}
                                            >
                                              +
                                            </button>
                                          )}

                                          {eligibilityQuestions.length > 1 && (
                                            <button
                                              type="button"
                                              className="btn btn-primary py-2 my-1 rounded-circle text-20"
                                              onClick={() => deleteQuestion(i)}
                                            >
                                              -
                                            </button>
                                          )}
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
  <div className="col-4 text-start my-3 btn-hover-effect">
    <h6
      onClick={() => handleGoBack()}
      className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
    >
      <span className="px-lg-4 px-2 text-12 fw-semibold">Back</span>
      <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
        <img src={blackarrow} alt="" />
      </i>
    </h6>
  </div>
  <div className="col-8 d-flex justify-content-between">
    <div className="col-4 text-end my-3 btn-hover-effect">
      <button
        onClick={handleSkip}
        className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2"
      >
        <span className="px-lg-4 px-2 text-12 fw-semibold text-black">Skip</span>
      </button>
    </div>
    <div className="col-4 text-end my-3 btn-hover-effect">
      <h6
        onClick={handleGoToNextStep}
        className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
      >
        <span className="px-lg-4 px-2 text-12 fw-semibold">Next</span>
        <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
          <img src={whitearrow} alt="" />
        </i>
      </h6>
    </div>
  </div>
</div>

                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EligibilityQuestions;
