import React, { FC, useEffect, useState } from "react";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import styles from "../../../../../components/work/styles/style.module.css";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import Occupations from "../../../../../assets/images/occupations.png";
import { useDispatch } from "react-redux";
import {
  setActiveStep,
  setPlanId,
  setPlanPrice,
} from "../../../../../redux/reducers/addPaidAds";
import { ADD_PAID_ADS } from "../../../../../shared/enums";
import { getAdPlans } from "../api";
import { PLAN_RESPONSE } from "../../../../../components/bostprofile/interface";
import tip from "../../../../../assets/images/tip.png";
import Toltip from "../../../../../components/common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
const AddPromotions: FC = () => {
  const [planData, setPlanData] = useState<PLAN_RESPONSE[]>([]);
  const [planID, setPlanID] = useState<string | null>("");
  const dispatch = useDispatch();

  const handleClickNext = () => {
    dispatch(setActiveStep(ADD_PAID_ADS.BOOST_ADD));
  };
  const handleClickBack = () => {
    dispatch(setActiveStep(ADD_PAID_ADS.UPLOAD_IMAGE));
  };

  const handleCheckboxChange = (planID: string, planPrice: number) => {
    setPlanID((prevID) => (prevID === planID ? null : planID));
    dispatch(setPlanId(planID));
    dispatch(setPlanPrice(planPrice));
  };

  const fetchAdPlans = async () => {
    try {
      const data = await getAdPlans();
      setPlanData(data.data);
    } catch (err) {
      console.log(err, "Error while fetching plans");
    }
  };

  useEffect(() => {
    fetchAdPlans();
  }, []);

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          {/* <ProgressBar now={20} /> */}
          <div className="contractpages">
            <form className="h-100">
              <div
                className={styles.registerOuter}
                style={{ position: "relative" }}
              >
                <div className="container h-100">
                  <div className=" h-100">
                    <div className={styles.registerBord}>
                      <div className="row pt-5 h-100 align-items-center">
                        <div className="col-md-6">
                          <div
                            className={styles.registerLeft}
                          >
                            <figure>
                              <img
                                src={Occupations}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className={styles.registerBord}>
                            <div className={styles.signUPContainer}>
                              
                                <div className="topTipHover">
                                  <div className="d-flex align-items-center">
                                    <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                                      Choose Your Ad Promotion Plan
                                    </h4>
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD
                                          .DATA.CHOOSE_YOUR_PLAN_FOR_PROMOTION
                                      }
                                    />
                                  </div>
                                </div>
                                <div className={styles.promotePlan} style={{marginTop: "30px"}}>
                                  <div className={styles.ScrollDiv}>
                                    <div className="row">
                                      {planData && planData?.length > 0 ? (
                                        planData?.map((plan, ind) => {
                                          return (
                                            <div
                                              key={ind}
                                              className="col-lg-6 d-flex"
                                            >
                                              <div
                                                className={`${styles.promotePlanCard} ${styles.active}`}
                                              >
                                                <input
                                                  type="checkbox"
                                                  name="plan"
                                                  checked={planID === plan._id}
                                                  onChange={() =>
                                                    handleCheckboxChange(
                                                      plan._id,
                                                      plan.price
                                                    )
                                                  }
                                                ></input>
                                                <h5>
                                                  ${plan.price} {plan.duration}{" "}
                                                  {plan.durationType}
                                                </h5>
                                                <p>
                                                  Promote your ad for a full
                                                  week to maximize visibility
                                                </p>
                                                <button
                                                  type="button"
                                                  className={`btn ${styles.btnClick}`}
                                                >
                                                  Pay per Week{" "}
                                                  <i className="fas fa-chevron-right"></i>{" "}
                                                </button>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <p className="text-white text-14">No Plans Available</p>
                                      )}
                                    </div>
                                  </div>
                              </div>

                              <div className="row align-items-center justify-content-between">
                                <div
                                  className="col-4 text-start my-3 btn-hover-effect"
                                  onClick={handleClickBack}
                                >
                                  <h6 className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton">
                                    <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                      <img src={blackarrow} alt="" />
                                    </i>
                                    <span className="px-lg-4 px-2 text-12 fw-semibold">
                                      Back
                                    </span>
                                  </h6>
                                </div>
                                <div className="col-8">
                                  <div
                                    className="d-flex align-items-center jusify-content-end"
                                    style={{ gap: "10px" }}
                                  >
                                    <div
                                      className="col-12 text-end my-3 btn-hover-effect"
                                      onClick={handleClickNext}
                                    >
                                      <h6 className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5">
                                        <span className="px-lg-4 px-2 text-12 fw-semibold">
                                          Next
                                        </span>
                                        <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                          <img src={whitearrow} alt="" />
                                        </i>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPromotions;
