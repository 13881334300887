import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import productoffered from "../../../assets/images/product_offered.jpg";
import EditProductsModal from "../modals/EditProductsModal";

const ProductOffered = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Products Offered
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img src={penbl} className="cursor-pointer ms-auto" alt="" onClick={openModal}/>
          </span>
        </div>
      </div>
      <div className="row align-items-center g-2">
        <div className="col-4">
          <div className=" border-grey p-2 rounded-2">
            <figure>
              <img src={productoffered} className="img-fluid w-100" alt="" />
            </figure>
            <div className=" mt-3 pt-3">
              <div className="newheadingstyle">
                <h4 className="text-16 fw-semibold mb-2">
                  Landing Page design in Figma
                </h4>
                <p>
                  <span className="text-12 mb-0 clamp clamp2">
                    Landing Page design in Figma (No coding, Only design mockup)
                  </span>
                </p>
                <p>
                  <span className="text-10 mb-0">
                    3 days delivery — Aug 9, 2024
                  </span>
                </p>
                <h5 className="text-14 fw-semibold">Price : $599</h5>
              </div>
            </div>
            <div className="mt-3">
              <div className="row align-items-center">
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-primary w-100 text-12 px-1  py-3 text-center "
                  >
                    Advertise This Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className=" border-grey p-2 rounded-2">
            <figure>
              <img src={productoffered} className="img-fluid w-100" alt="" />
            </figure>
            <div className=" mt-3 pt-3">
              <div className="newheadingstyle">
                <h4 className="text-16 fw-semibold mb-2">
                  Landing Page design in Figma
                </h4>
                <p>
                  <span className="text-12 mb-0 clamp clamp2">
                    Landing Page design in Figma (No coding, Only design mockup)
                  </span>
                </p>
                <p>
                  <span className="text-10 mb-0">
                    3 days delivery — Aug 9, 2024
                  </span>
                </p>
                <h5 className="text-14 fw-semibold">Price : $599</h5>
              </div>
            </div>
            <div className="mt-3">
              <div className="row align-items-center">
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-primary w-100 text-12 px-1  py-3 text-center "
                  >
                    Advertise This Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className=" border-grey p-2 rounded-2">
            <figure>
              <img src={productoffered} className="img-fluid w-100" alt="" />
            </figure>
            <div className=" mt-3 pt-3">
              <div className="newheadingstyle">
                <h4 className="text-16 fw-semibold mb-2">
                  Landing Page design in Figma
                </h4>
                <p>
                  <span className="text-12 mb-0 clamp clamp2">
                    Landing Page design in Figma (No coding, Only design mockup)
                  </span>
                </p>
                <p>
                  <span className="text-10 mb-0">
                    3 days delivery — Aug 9, 2024
                  </span>
                </p>
                <h5 className="text-14 fw-semibold">Price : $599</h5>
              </div>
            </div>
            <div className="mt-3">
              <div className="row align-items-center">
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-primary w-100 text-12 px-1 py-3 text-center "
                  >
                    Advertise This Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h4 className="text-14 fw-semibold text-blue">+ Add more</h4>
      </div>
      <EditProductsModal showModal={showModal} closeModal={closeModal} title="Edit Products"/>
    </div>
  );
};

export default ProductOffered;
