import React, { useState } from "react";
import { Dropdown, FormControl, ModalBody, ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import whitearrow from "../../../assets/images/whitearrow.svg";
import blackarrow from "../../../assets/images/blackarrow.svg";
import upload from "../../../assets/images/upload.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "../styles/style.module.css";
import { uploadFile } from "../../../common/api";
import { updateUserProfile } from "../../home/api";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  refresh: () => void;
}

const EditCertificateModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
  refresh
}) => {
  const [name, setName] = useState<string>("");
  const [file, setFile] = useState<any>("")
  const [startDate, setStartDate] = useState<any>("")
  const [endDate, setEndDate] = useState<any>("")


  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData()
      Array.from(files).forEach((img) => {
        formData.append("file", img);
      });
      try {
        const response = await uploadFile(formData);
        const newFileUrl = response.data.files;
        setFile(newFileUrl);
        await console.log(file, "file new");
      } catch (err) { }
    }
  }

  const handleCertificateUpload = async () => {
    if (file) {
      const payload = {
        docFiles: [{
          file: file,
          name: name,
          startDate: startDate,
          endDate: endDate
        }]
      }
      try {
        await updateUserProfile(payload);
        refresh();
      } catch (error) {
        console.log("Error occuring while uploading the resume", error);
      }
    }
  }
  return (
    <>
      <Modal show={showModal} onHide={closeModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div className="addModalPro">
            <form>
              <div className="row">
                <div className="col-12">
                  <div className="formGroup">
                    <label>Name</label>
                    <div>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        placeholder="Add or choose Certificate"
                      />
                    </div>
                    {/* <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                      >
                        Please select an option
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="">
                          <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Search..."
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: "300px",
                            // overflowY: "scroll",
                          }}
                        >
                            <Dropdown.Item disabled>
                              No options found
                            </Dropdown.Item>
                          

                          <div className={styles.addOccuatin}>
                            <div className="col-8">
                              <input
                                type="text"
                                placeholder="Type an occupation here you want to add..."
                                name="customOccupation"
                              />
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>Issue Date</label>
                    <div>
                      <input
                        type="Date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control"
                        placeholder="02/25/2024"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>Expiry date</label>
                    <div>
                      <input
                        type="Date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control"
                        placeholder="02/25/2024"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="formGroup">
                    <label>Upload Image</label>
                    <div
                      className={styles.uploadimagefile}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                        onChange={({ target }) =>
                          handleFileUpload(target.files)
                        }
                      />
                      <div className={styles.uploadimage}>
                        <i>
                          <img src={upload} alt="" />
                        </i>
                        <p className="mb-0 text-14 text-blue">
                          Upload jpeg/png file
                        </p>
                      </div>
                    </div>
                    {/* {img && (
                    <li>
                      <div className={styles.uploadImages}>
                        <img src={img} alt="" className="img-fluid" />
                        <i
                          className="fas fa-times"
                          onClick={removeUploadedFiles} // Call the function to remove the image
                        ></i>
                      </div>
                    </li>
                  )} */}
                  </div>
                </div>

              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto">
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-lg-4 p-3">
              <img
                src={blackarrow}
                alt=""
                width={15}
                height={15}
                style={{ transform: "rotate(-180deg)" }}
              />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={handleCertificateUpload}
            className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Save
            </span>
            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-4">
              <img src={whitearrow} alt="" width={15} height={15} />
            </i>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditCertificateModal;
