import React from "react";
import styles from "../../styles/styles.module.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setBackgroundCheck,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";

const BackGroundCheck: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { backgroundCheck } = useSelector(
    (state: RootState) => state.addContract
  );
  const { backgroundRegecy, isBackgroundCheckRequired } = backgroundCheck;

  const formIk = useFormik({
    initialValues: {
      backgroundRegecy,
      isBackgroundCheckRequired,
    },

    onSubmit: () => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.PROCEDUCERS_POLICIES));
    },
  });

  const onValueChange = (value: string | boolean, key: string): void => {
    const updatedData = {
      ...backgroundCheck,
      [key]: value,
    };
    dispatch(setBackgroundCheck(updatedData));
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SECURITY_REQUIREMENT));
  };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.PROCEDUCERS_POLICIES));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.PROCEDUCERS_POLICIES));
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
            <form onSubmit={formIk.handleSubmit}>
              <div className={styles.registerOuter}>
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className={styles.registerLeft}>
                          <h4 className="text-white text-32 fw-semibold">
                            Will the applicants have to take a background check?
                          </h4>
                        </div>
                        <div className="col-lg-11 col-12">
                          {/* <p>Add a Legal information of the work that you need</p> */}
                          <figure>
                            <img
                              src={Occupations}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className={styles.signUPContainers}>
                          <div className={styles.registerRight}>
                            <>
                              <div className={styles.fromGroup}>
                                <label>Background check Required</label>
                                <ul
                                  className={`${styles.radioBTn} ${styles.radStepOne}`}
                                  style={{ flexDirection: "column" }}
                                >
                                  <li style={{ background: "transparent" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`isBackgroundCheckRequired$`}
                                        id={`isBackgroundCheckRequired1$`}
                                        checked={isBackgroundCheckRequired}
                                        onChange={() =>
                                          onValueChange(
                                            true,
                                            "isBackgroundCheckRequired"
                                          )
                                        }
                                      />
                                      <label className="form-check-label text-black">
                                        Yes
                                      </label>
                                    </div>
                                  </li>

                                  <li style={{ background: "transparent" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`isBackgroundCheckRequired$`}
                                        checked={!isBackgroundCheckRequired}
                                        onChange={() =>
                                          onValueChange(
                                            false,
                                            "isBackgroundCheckRequired"
                                          )
                                        }
                                      />
                                      <label className="form-check-label text-black">
                                        No
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              {isBackgroundCheckRequired && (
                                <div className={styles.fromGroup}>
                                  <label className={styles.starlabel}>
                                    Background check Recency
                                  </label>
                                  <div className={styles.AddBtn}>
                                    <input
                                      type="text"
                                      placeholder="How many days old can the Check be?"
                                      className="form-control h-48 rounded-pill"
                                      value={backgroundRegecy}
                                      onChange={({ target: { value } }) =>
                                        onValueChange(value, "backgroundRegecy")
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-4 text-start my-3 btn-hover-effect">
                            <h6
                              onClick={() => handleGoBack()}
                              className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                            >
                              <span className="px-lg-4 px-2 text-12 fw-semibold">
                                Back
                              </span>
                              <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                <img src={blackarrow} alt="" />
                              </i>
                            </h6>
                          </div>
                          <div className="col-8 d-flex justify-content-between">
                            <div className="col-4 text-end my-3 btn-hover-effect">
                              <button
                                onClick={handleSkip}
                                className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                                  Skip
                                </span>
                              </button>
                            </div>
                            <div className="col-4 text-end my-3 btn-hover-effect">
                              <h6
                                onClick={handleGoToNextStep}
                                className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold">
                                  Next
                                </span>
                                <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={whitearrow} alt="" />
                                </i>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackGroundCheck;
