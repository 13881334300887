import React from "react";
import styles from "../styles/contractnew.module.css";
import check from "../../../assets/images/register_icon.svg";
import whitearrow from "../../../assets/images/whitearrow.svg";
import blackarrow from "../../../assets/images/blackarrow.svg";
import { Link, useNavigate } from "react-router-dom";
import storage from "../../../utils/storage";
import { useDispatch } from "react-redux";
import { setActiveStep } from "../../../redux/reducers/addContract";
import HeaderContent from "../../../shared/Components/HeaderContent/headerContent";
import { ADD_CONTRACT_STEPS } from "../../../shared/enums";

const Dashboard = () => {
  const navigate = useNavigate();
  const token = storage.getToken();
  const dispatch = useDispatch();

  const startPosting = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.POST_TYPE));
    navigate("/add-contract");
  };

  return (
    <>
      <div className={styles.regpage}>
        <div style={{ height: "calc(100% - 87px)" }}>
          <HeaderContent />
          <div className="h-100">
            <div className="container h-100">
              <div className="col-12 h-100">
                <div className="d-flex align-items-center justify-content-center flex-column h-100">
                  {token ? (
                    <>
                      <div className="text-center">
                        <span className="check">
                          <i className="fa-solid fa-circle-check"></i>
                        </span>
                        <span className="text-center d-flex align-items-center justify-content-center mb-3">
                          <img src={check} alt="" className="img-fluid" />
                        </span>
                        <h5 className="fw-bold text-blue text-28">
                          Registration Successfull
                        </h5>
                        <p className="text-14 fw-normal">You are all set up!</p>
                        <label className="btn btn-white needhelp text-black font-poppins fw-normal">
                          Add additional information to your profile ›
                        </label>
                      </div>
                      <div className="text-center my-3 btn-hover-effect">
                        <Link
                          to={"/home"}
                          className="btn bg-white arrowblue border-blue border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                        >
                          <span className="px-lg-4 px-2 font-semibold">Go to dashboard</span>
                          <i className="bg-blue rounded-circle arroweffect position-absolute end-0 w-25 h-100 top-0 border-blue d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="py-4 text-center">
                      <p className="mb-0 text-14 text-black fw-normal">
                        Don’t have an account?{" "}
                        <Link
                          to="/signup"
                          className="text-14 text-blue fw-bold text-decoration-none"
                        >
                          Sign up
                        </Link>
                        /
                        <Link
                          to="/login"
                          className="text-14 text-blue fw-bold text-decoration-none"
                        >
                          Login
                        </Link>
                      </p>
                    </div>
                  )}
                  <div className="text-center bg-blue text-white py-4 px-4 rounded-4 col-12">
                    <h6 className="text-uppercase text-semibold">
                      Do more with Automate America!
                    </h6>
                    <div className="col-12 mx-auto">
                      <div className="row pt-4 pb-0">
                        <div className="col-md-4">
                          <div className="px-lg-4">
                            <h4 className="text-20 fw-semibold font-poppins">
                              Post
                            </h4>
                            <p className="text-14 fw-normal opacity-75">
                              contract,jobs,paid ads and <br />
                              posts &apos;s here.
                            </p>
                            <div className="text-center my-3 btn-hover-effect">
                              <h6
                                onClick={() => startPosting()}
                                className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold">
                                  Start Posting
                                </span>
                                <i className="bg-yellow rounded-circle arroweffect position-absolute end-0 h-100 top-0 border-yellow d-flex align-items-center justify-content-center ms-3">
                                  <img src={blackarrow} alt="" />
                                </i>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4 border-lg-start border-lg-end Search-opportunities-border"
                          style={{ borderColor: "rgba(255, 255, 255, 0.5)" }}
                        >
                          <div className="px-lg-4">
                            <h4 className="text-20 fw-semibold font-poppins">
                              Search Opportunities
                            </h4>
                            <p className="text-14 fw-normal opacity-75">
                              Search contract,jobs,paid ads and <br />
                              posts &apos;s here.
                            </p>
                            <div className="text-center my-3 btn-hover-effect">
                              <Link
                                to={"/opportunity"}
                                className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold">
                                  Search Opportunities
                                </span>
                                <i className="bg-yellow rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={blackarrow} alt="" />
                                </i>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="px-lg-4">
                            <h4 className="text-20 fw-semibold font-poppins">
                              Find Professionals
                            </h4>
                            <p className="text-14 fw-normal opacity-75">
                              Search and Request Professionals <br />
                              Here
                            </p>
                            <div className="text-center my-3 btn-hover-effect">
                              <Link
                                to={"/findProfessionals"}
                                className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold">
                                  Search & Request Pros
                                </span>
                                <i className="bg-yellow rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={blackarrow} alt="" />
                                </i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Toltip /> */}

    </>
  );
};

export default Dashboard;
