import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, Modal, ModalFooter } from "react-bootstrap";
import penbl from "../../../assets/images/penbl.png";
import upload from "../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import blackarrow from "../../../assets/images/blackarrow.svg";
import whitearrow from "../../../assets/images/whitearrow.svg";
import { MASTER_TYPE, SKILL_TYPE } from "../../../utils/enums/masterTypes.enum";
import { updateUserProfile } from "../../home/api";
import { getMasterTypes } from "../../../feature/auth/signup/api";
import { IOption } from "../../../Interfaces";
import { uploadFileImg } from "../../Posts/api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import { date } from "yup";
import { ProfExperience } from "../interface";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  refresh: () => void;
  indexNumber: number | null;
  profExpData: ProfExperience[];
  handleEdit: (updatedData: ProfExperience) => void;
  initialData: ProfExperience | null;
}

const ProfExpModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  profExpData = [],
  refresh,
  indexNumber,
  initialData,
}) => {
  if (!isOpen) return null;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [occupation, setOccupation] = useState("");
  const [occupationId, setOccupationId] = useState("");
  const [occuData, setOccuData] = useState<IOption[]>([]);
  const [company, setCompany] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyData, setCompanyData] = useState<IOption[]>([]);
  const [img, setImg] = useState<any>("");
  const [newRes, setNewPRes] = useState<any>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isCurrent, setIsCurrent] = useState<boolean>(false);
  const currentDate = moment(Date.now()).format("YYYY-MM-DD");
  const [index, setIndex] = useState<number | null>(null);

  useEffect(() => {
    if (initialData) {
      // If there is initial data, pre-fill the form fields
      setOccupation(initialData?.occupationDetail?.name || "");
      setOccupationId(initialData?.occupationDetail?._id || "");
      setCompany(initialData?.companyDetail?.name || "");
      setCompanyId(initialData?.companyDetail?._id || "");
      // setImg(initialData?.image || null);
      setNewPRes(initialData?.description || "");
      setStartDate(initialData?.from || "");
      setEndDate(initialData?.to || "");
      setIndex(indexNumber);
    }
  }, []);
console.log("initialData",initialData)
  const editorConfiguration = {
    toolbar: ["undo", "redo", "|", "bold", "italic", "ckwebspeech"],
    height: "150px",
  };

  const loadDropDowns = async () => {
    try {
      const occupationDataRes = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
      const companyRes = await getMasterTypes(MASTER_TYPE.COMPANIES);

      if (occupationDataRes?.data || companyRes?.data) {
        setOccuData(
          occupationDataRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setCompanyData(
          companyRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to load data", error);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleOccupationChange = (label: string, value: string) => {
    setOccupation(label);
    setOccupationId(value);
  };

  const handleCompanyChange = (label: string, value: string) => {
    setCompany(label);
    setCompanyId(value);
  };

  const handleSubmitCustomOccupation = async () => {
    // const payload = {
    //   type: MASTER_TYPE.OCCUPATIONS,
    //   name: searchTerm,
    //   alias: searchTerm,
    // };
    // toast.promise(postMasterType(payload), {
    //   pending: {
    //     render() {
    //       return MESSAGES.POSTING_CONTRACTS.VERIFYING;
    //     },
    //   },
    //   success: {
    //     render(newOccupation) {
    //       formik.setFieldValue(
    //         "primaryOccupation",
    //         newOccupation.data.data.name
    //       );
    //       setFilterValue("");
    //       setSearchTerm("");
    //       dispatch(
    //         setOccupation({
    //           ...occupation,
    //           primaryOccupation: {
    //             label: newOccupation.data.data.name,
    //             value: newOccupation.data.data._id,
    //           },
    //         })
    //       );
    //       return MESSAGES.POSTING_CONTRACTS.ADDING;
    //     },
    //   },
    //   error: {
    //     render(error) {
    //       let errorMesage = error as APIResponseError;
    //       return errorMesage?.data?.data?.message;
    //     },
    //   },
    // });
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("files", img);
      });
      try {
        const response = await uploadFileImg(formData);
        const newImageUrl = response.data.files[0];
        setImg(newImageUrl);
      } catch (err) {}
    }
  };

  const removeUploadedFiles = (): void => {
    // if (fileIndex >= 0 && fileIndex < img.length) {
    //   const updatedFiles = img.filter((_, index) => index !== fileIndex); // Remove the image at the specified index
    //   setImg(""); // Update the state with the new array
    // }
    setImg("");
  };

  const handleIsCurrent = () => {};

  console.log(isCurrent, "Is current");

  const updateExperience = async () => {
    if (occupation && company && startDate && endDate && newRes || img) {
      if (currentDate >= startDate && currentDate <= endDate) {
        setIsCurrent(true);
      } else {
        setIsCurrent(false);
      }
      console.log(isCurrent, "Is current");
      const payload = {
        isCurrent: isCurrent,
        occupationId: occupationId,
        description: newRes,
        companyId: companyId,
        from: startDate,
        to: endDate,
      };
      try {
        let existingData: any = [];
        if (profExpData?.length && initialData) {
          existingData = profExpData?.map((data, ind) => {
            if (ind === index) {
              return {
                isCurrent: payload.isCurrent,
                occupationId: payload.occupationId,
                description: payload.description,
                companyId: payload.companyId,
                from: payload.from,
                to: payload.to,
                _id:data._id
              };
            } else {
              return {
                isCurrent: data.isCurrent,
                occupationId: data.occupationDetail._id,
                description: data.description,
                companyId: data.companyDetail._id,
                from: data.from,
                to: data.to,
                _id:data._id
              };
            }
          });
        } else {
          if (profExpData?.length) {
            existingData = profExpData?.map((data, ind) => {
              return {
                isCurrent: data.isCurrent,
                occupationId: data.occupationDetail._id,
                description: data.description,
                companyId: data.companyDetail._id,
                from: data.from,
                to: data.to,
                _id:data._id
              };
            });
          }
          existingData = [...existingData, payload];
        }

        let updatedData = {
          workExperiences: existingData,
        };
        await updateUserProfile(updatedData);
        refresh();
        onClose();
      } catch (error) {
        console.log(
          "Error occuring while updating the professional experience",
          error
        );
      }
    }
  };

  const occuOptions =
    filterValue && filterValue !== ""
      ? occuData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : occuData;

  const companyOptions =
    filterValue && filterValue !== ""
      ? companyData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : companyData;

  return (
    
    <>
    <Modal show={isOpen} onHide={onClose} centered size="lg">
     <Modal.Header closeButton>
       <Modal.Title>{title}</Modal.Title>
     </Modal.Header>
     <form>
       <Modal.Body>       
        <div className="addModalPro">
          <form>
            <div className="row">
              <div className="col-12">
                <div className="formGroup">
                  <label>Job Tittle</label>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                      >
                        {occupation || "Please select an option"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="">
                          <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Search..."
                            onChange={(e) => setFilterValue(e.target.value)}
                            value={filterValue}
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: "300px",
                            // overflowY: "scroll",
                          }}
                        >
                          {occuOptions.length === 0 ? (
                            <Dropdown.Item disabled>
                              No options found
                            </Dropdown.Item>
                          ) : (
                            [
                              {
                                label: "Select primary occupation",
                                value: "",
                              },
                              ...occuOptions,
                            ].map((option, index: number) => (
                              <>
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    handleOccupationChange(
                                      option.label,
                                      option.value
                                    )
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              </>
                            ))
                          )}

                          <div className={styles.addOccuatin}>
                            <div className="col-8">
                              <input
                                type="text"
                                placeholder="Type an occupation here you want to add..."
                                name="customOccupation"
                                value={searchTerm}
                                onChange={(e) =>
                                  setSearchTerm(e.target.value)
                                }
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSubmitCustomOccupation();
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="formGroup">
                  <label>Company Name</label>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                      >
                        {company || "Please select an option"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="">
                          <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Search..."
                            onChange={(e) => setFilterValue(e.target.value)}
                            value={filterValue}
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: "300px",
                            // overflowY: "scroll",
                          }}
                        >
                          {companyOptions.length === 0 ? (
                            <Dropdown.Item disabled>
                              No options found
                            </Dropdown.Item>
                          ) : (
                            [
                              {
                                label: "Select primary occupation",
                                value: "",
                              },
                              ...companyOptions,
                            ].map((option, index: number) => (
                              <>
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    handleCompanyChange(
                                      option.label,
                                      option.value
                                    )
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              </>
                            ))
                          )}

                          <div className={styles.addOccuatin}>
                            <div className="col-8">
                              <input
                                type="text"
                                placeholder="Type an occupation here you want to add..."
                                name="customOccupation"
                                value={searchTerm}
                                onChange={(e) =>
                                  setSearchTerm(e.target.value)
                                }
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSubmitCustomOccupation();
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Start Date</label>
                  <div>
                    <input
                      type="Date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="form-control"
                      placeholder="02/25/2024"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Last date</label>
                  <div style={{ marginBottom: "30px" }}>
                    <input
                      type="Date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="form-control"
                      placeholder="02/25/2024"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="formGroup">
                  <label>Responsibilities</label>
                  <div style={{ marginBottom: "30px" }}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={newRes}
                      config={editorConfiguration}
                      onChange={(event, editor) =>
                        setNewPRes(editor.getData())
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="formGroup">
                  <label>Upload Image</label>
                  <div
                    className={styles.uploadimagefile}
                    style={{ marginBottom: "30px" }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                      onChange={({ target }) =>
                        handleFileUpload(target.files)
                      }
                    />
                    <div className={styles.uploadimage}>
                      <i>
                        <img src={upload} alt="" />
                      </i>
                      <p className="mb-0 text-14 text-blue">
                        Upload jpeg/png file
                      </p>
                    </div>
                  </div>
                  {img && (
                    <li>
                      <div className={styles.uploadImages}>
                        <img src={img} alt="" className="img-fluid" />
                        <i
                          className="fas fa-times"
                          onClick={removeUploadedFiles} // Call the function to remove the image
                        ></i>
                      </div>
                    </li>
                  )}
                </div>
              </div>
            
            </div>
          </form>
        </div>
       </Modal.Body>
       <ModalFooter>
         <div className="btn-hover-effect">
           <h6 onClick={onClose} className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
                             >
             <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
               Back
             </span>
             <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-lg-4 p-3">
               <img src={blackarrow} alt="" style={{transform: "rotate(-180deg)"}}/>
             </i>
           </h6>
         </div>
         <div className="btn-hover-effect">
           <h6 onClick={updateExperience} className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto">
             <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
               Save
             </span>
             <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-lg-4 p-3">
               <img src={whitearrow} alt=""/>
             </i>
           </h6>
         </div>
         
       </ModalFooter>
     </form>
     
    </Modal>
   </>
  );
};

export default ProfExpModal;
