import React from "react";
import styles from "../../styles/styles.module.css";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setDrugTest,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";

const DrugTest: React.FC = () => {
  const dispatch = useDispatch();
  const { drugTest } = useAppSelector((state: RootState) => state.addContract);
  const { isDrugTestRequired, drugRegacy } = drugTest;

  const formIk = useFormik({
    initialValues: { isDrugTestRequired, drugRegacy },
    onSubmit: () => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.BACKGROUND_CHECK));
    },
  });

  const onValueChange = (value: string | boolean, key: string): void => {
    const updatedSafetyRequirements = {
      ...drugTest,
      [key]: value,
    };
    dispatch(setDrugTest(updatedSafetyRequirements));
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SOFWARE_LICENSES));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.BACKGROUND_CHECK));
  };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.BACKGROUND_CHECK));
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
            <form onSubmit={formIk.handleSubmit}>
              <div className={styles.registerOuter}>
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className={styles.registerLeft}>
                          <h4 className="text-white text-32 fw-semibold">
                            Will applicants be required to have a recent drug
                            test?
                          </h4>
                          {/* <p className="text-16 text-white opacity-75">
                      You can provide all the information here regarding the
                      contract; you just need to fill in all the details here.
                    </p> */}

                          <div className="col-lg-11 col-12">
                            {/* <p>Add a Legal information of the work that you need</p> */}
                            <figure>
                              <img
                                src={Occupations}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={styles.signUPContaine}>
                          <div className={styles.registerRight}>
                            <>
                              <div className={styles.fromGroup}>
                                <label>Drug Test Required</label>
                                <ul
                                  className={`${styles.radioBTn} ${styles.radStepOne}`}
                                  style={{ flexDirection: "column" }}
                                >
                                  <li style={{ background: "transparent" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`isDrugTestRequired$`}
                                        id={`isDrugTestRequired$`}
                                        checked={isDrugTestRequired}
                                        onChange={() =>
                                          onValueChange(
                                            true,
                                            "isDrugTestRequired"
                                          )
                                        }
                                      />
                                      <label className="form-check-label">
                                        Yes
                                      </label>
                                    </div>
                                  </li>
                                  <li style={{ background: "transparent" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`isDrugTestRequired$`}
                                        id={`isDrugTestRequired$`}
                                        checked={!isDrugTestRequired}
                                        onChange={() =>
                                          onValueChange(
                                            false,
                                            "isDrugTestRequired"
                                          )
                                        }
                                      />
                                      <label className="form-check-label">
                                        No
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              {isDrugTestRequired && (
                                <>
                                  <div className={styles.fromGroup}>
                                    <label className={styles.starlabel}>
                                      Drug Test Recency
                                    </label>
                                    <div className={styles.AddBtn}>
                                      <input
                                        type="text"
                                        className="form-control h-48 rounded-pill"
                                        placeholder="How many days old can the test be?"
                                        value={drugRegacy}
                                        onChange={({ target: { value } }) =>
                                          onValueChange(value, "drugRegacy")
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-4 text-start my-3 btn-hover-effect">
                            <h6
                              onClick={() => handleGoBack()}
                              className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                            >
                              <span className="px-lg-4 px-2 text-12 fw-semibold">
                                Back
                              </span>
                              <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                <img src={blackarrow} alt="" />
                              </i>
                            </h6>
                          </div>
                          <div className="col-8 d-flex justify-content-between">
                            <div className="col-4 text-end my-3 btn-hover-effect">
                              <button
                                onClick={handleSkip}
                                className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                                  Skip
                                </span>
                              </button>
                            </div>
                            <div className="col-4 text-end my-3 btn-hover-effect">
                              <h6
                                onClick={handleGoToNextStep}
                                className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold">
                                  Next
                                </span>
                                <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={whitearrow} alt="" />
                                </i>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrugTest;
