import React, { useEffect, useState } from "react";
import penbl from "../../../assets/images/penbl.png";
import styles from "../styles/style.module.css";
import avtar from "../../../assets/images/avatar.png";
import cam from "../../../assets/images/rcupload.png";
import upload from "../../../assets/images/upload.svg";
import blackarrow from "../../../assets/images/blackarrow.svg";
import trash from "../../../assets/images/trash.png";
import whitearrow from "../../../assets/images/whitearrow.svg";
import EditImageModal from "./EditImageModal";
import { uploadFileImg } from "../../Posts/api";
import { Modal, ModalFooter } from "react-bootstrap";
import { updateUserProfile } from "../../home/api";

interface ModalProps {
  modal2: boolean;
  modal3: boolean;
  openModal3: () => void;
  closeModal2: () => void;
  closeModal3: () => void;
  refresh: () => void;
  title: string;
  img: string;
}

const ViewImageModal: React.FC<ModalProps> = ({
  title,
  modal2,
  modal3,
  openModal3,
  closeModal2,
  closeModal3,
  refresh,
  img,
}) => {
  // if (!modal2) return null;
  const [image, setImage] = useState<string>(img);

  // useEffect(() => {
  //   if(img){
  //     setImage(img || "");
  //   }
  // }, [img]);

  const handleFileUpload = async (files: FileList | null) => {
    console.log(files, "filesfilesfilesfilesfiles EDIT");

    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("files", img);
      });
      console.log(formData, "formDataformData EDIT");
      try {
        const response = await uploadFileImg(formData);
        const newImageUrl = response.data.files[0];
        setImage(newImageUrl);
        console.log(image, "image EDIT ", response);
      } catch (err) {
        console.log("Error ocuring while uploading the image", err);
      }
    }
  };

  console.log(image, "new image");

  const removeUploadedFiles = (): void => {
    // if (fileIndex >= 0 && fileIndex < img.length) {
    //   const updatedFiles = img.filter((_, index) => index !== fileIndex); // Remove the image at the specified index
    //   setImg(""); // Update the state with the new array
    // }
    setImage("");
  };

  const handleInfoChange = async () => {
    if (image) {
      const payload = {
        profilePicture: image,
      };

      try {
        await updateUserProfile(payload);
        refresh();
        closeModal2();
      } catch (error) {
        console.log(
          "Error occuring while updating the user profile picture",
          error
        );
      }
    }
  };

  return (
    <>
      <Modal show={modal2} onHide={closeModal2} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="addModalPro">
              <div className="row align-items-center justify-content-center">
                <div className="col-4">
                  <div className="col-12 mb-4 text-center">
                    <li
                      style={{
                        // display: "inline-block",
                        // background: "#fef8f5",
                        border: "1px solid #fef8f5",
                        width: "240px",
                        height: "240px",
                        minHeight: "240px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // borderWidth: 1,
                        // borderColor: "red"
                      }}
                    >
                      <div className={styles.uploadImages}>
                        {image && (
                          <>
                            <img
                              src={image}
                              alt=""
                              className="img-fluid"
                              // width={"200px"}
                              // height={"200px"}
                              style={{
                                objectFit: "cover",
                                borderRadius: "50%",
                                width: "240px",
                                height: "240px",
                                // minHeight: "240px",
                              }}
                            />
                            <i
                              className="fas fa-times"
                              onClick={removeUploadedFiles} // Call the function to remove the image
                            ></i>
                          </>
                        )}
                      </div>
                    </li>
                  </div>
                </div>
                <div className="col-8">
                  <div className="row align-items-center justify-content-center justify-content-center">
                    <div className="col-12 mb-4">
                      <label className="fw-semibold mb-2">
                        Upload Photo from galery
                      </label>
                      <div className={styles.uploadimagefile}>
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                          onChange={({ target }) =>
                            handleFileUpload(target.files)
                          }
                        />
                        <div className={styles.uploadimage}>
                          <i>
                            <img src={upload} alt="" />
                          </i>
                          <p className="mb-0 text-14 text-blue">
                            Upload Photo from galery
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <h6
                        onClick={() => openModal3()}
                        className="btn btn-lightwhiteblue border rounded-pill d-flex align-items-center justify-content-center mx-auto py-2"
                      >
                        <i className="me-2">
                          <img src={penbl} className="img-fluid" alt="" />
                        </i>
                        <span className="text-black">Edit Photo</span>
                      </h6>
                    </div>
                    <div className="col-6 text-center">
                      <h6 className="btn bg-lightorange border w-100 rounded-pill d-flex align-items-center justify-content-center py-2 mx-auto">
                        <i className="me-2">
                          <img src={trash} className="img-fluid" alt="" />
                        </i>
                        <span className="text-danger">Delete Photo</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <ModalFooter>
            <div className="btn-hover-effect">
              <h6
                onClick={closeModal2}
                className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                  Back
                </span>
                <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-lg-4 p-3">
                  <img
                    src={blackarrow}
                    alt=""
                    width={15}
                    height={15}
                    style={{ transform: "rotate(-180deg)" }}
                  />
                </i>
              </h6>
            </div>
            <div className="btn-hover-effect">
              <h6
                onClick={handleInfoChange}
                className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto"
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                  Save
                </span>
                <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-lg-4 p-3">
                  <img src={whitearrow} alt="" width={15} height={15} />
                </i>
              </h6>
            </div>
            {/* <div className="btn-hover-effect">
          <h6 onClick={handleInfoChange} className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto">
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Save
            </span>
            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-4">
              <img src={whitearrow} alt="" width={15} height={15}/>
            </i>
          </h6>
        </div> */}
          </ModalFooter>
        </form>
      </Modal>

      {modal3 && (
        <EditImageModal
          modal3={modal3}
          closeModal3={closeModal3}
          title="Edit Image"
          img={image}
          editedImage={(cb: any) => {
            if (typeof cb === "string") {
              setImage(cb)
            }
          }}
        />
      )}
    </>
    // <div className="modal" style={{ display: "block" }}>
    //   <div className="modal-dialog addModal educationModal modal-dialog-centered">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title">{title}</h5>
    //         <button
    //           type="button"
    //           className="btn-close"
    //           data-bs-dismiss="modal"
    //           aria-label="Close"
    //           onClick={onCloseModal}
    //         ></button>
    //       </div>
    //       <div className="modal-body">
    //         <div className="addModalPro">
    //           <div className="row align-items-center justify-content-center">
    //             <div className="col-4">
    //               <div className="col-12 mb-4 text-center">
    //                 {image && (
    //                   <li>
    //                     <div className={styles.uploadImages}>
    //                       <img
    //                         src={image}
    //                         alt=""
    //                         className="img-fluid"
    //                         width={120}
    //                         height={120}
    //                       />
    //                       <i
    //                         className="fas fa-times"
    //                         onClick={removeUploadedFiles} // Call the function to remove the image
    //                       ></i>
    //                     </div>
    //                   </li>
    //                 )}
    //               </div>
    //             </div>
    //             <div className="col-8">
    //               <div className="row align-items-center justify-content-center justify-content-center">
    //                 <div className="col-12 mb-4">
    //                   <label className="fw-semibold mb-2">
    //                     Upload Photo from galery
    //                   </label>
    //                   <div className={styles.uploadimagefile}>
    //                     <input
    //                       type="file"
    //                       accept="image/*"
    //                       className="form-control"
    //                       placeholder="eg : Version 1.4, IRC5, Mustang Etc."
    //                       onChange={({ target }) =>
    //                         handleFileUpload(target.files)
    //                       }
    //                     />
    //                     <div className={styles.uploadimage}>
    //                       <i>
    //                         <img src={upload} alt="" />
    //                       </i>
    //                       <p className="mb-0 text-14 text-blue">
    //                         Upload Photo from galery
    //                       </p>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-6">
    //                   <h6
    //                     onClick={() => openModal()}
    //                     className="btn btn-lightwhiteblue border rounded-pill d-flex align-items-center justify-content-center mx-auto py-2"
    //                   >
    //                     <i className="me-2">
    //                       <img src={penbl} className="img-fluid" alt="" />
    //                     </i>
    //                     <span className="text-black">Edit Photo</span>
    //                   </h6>
    //                 </div>
    //                 <div className="col-6 text-center">
    //                   <h6 className="btn bg-lightorange border w-100 rounded-pill d-flex align-items-center justify-content-center py-2 mx-auto">
    //                     <i className="me-2">
    //                       <img src={trash} className="img-fluid" alt="" />
    //                     </i>
    //                     <span className="text-danger">Delete Photo</span>
    //                   </h6>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <EditImageModal
    //     isOpenModal={isModalOpen}
    //     onCloseModal={closeModal}
    //     title="Edit Image"
    //     img={img}
    //   />
    // </div>
  );
};

export default ViewImageModal;
