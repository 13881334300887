import React from "react";
import styles from "../../../styles/style.module.css";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setExpenseData,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { SaveAsDraftButton } from "../../Components";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";

const AddExpense: React.FC = () => {
  const dispatch = useDispatch();
  const { expenseData } = useSelector((state: RootState) => state.addContract);

  const formIk: any = useFormik({
    initialValues: {
      isExpenseReimbursed: expenseData.isExpenseReimbursed,
      dailyPerDiem: expenseData.dailyPerDiem,
      travelDayPerDiem: expenseData.travelDayPerDiem,
      hotelAllowance: expenseData.hotelAllowance,
      rentalCarAllowance: expenseData.rentalCarAllowance,
      fuel: expenseData.fuel,
      airFareAllowance: expenseData.airFareAllowance,
      mileage: expenseData.mileage,
    },

    onSubmit: () => {
      if (expenseData.isExpenseReimbursed === true) {
        dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_MORE_EXPENSES));
      } else {
        dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
      }
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_RATES));
  };
  // const handleGoToNextStep = () => {
  //   if (expenseData.isExpenseReimbursed === true) {
  //     dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_MORE_EXPENSES));
  //   } else {
  //     dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
  //   }
  // };

  const onChange = (value: string | boolean | number, key: string) => {
    formIk.setFieldValue(key, value);
    dispatch(
      setExpenseData({
        ...expenseData,
        [key]: value,
      })
    );
  };

  const {
    isExpenseReimbursed,
    travelDayPerDiem,
    dailyPerDiem,
    hotelAllowance,
  } = expenseData;

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <form onSubmit={formIk.handleSubmit}>
            <div
              className={styles.registerOuter}
              style={{ position: "relative" }}
            >
              <div className="container">
                <div className={styles.registerBord}>
                  <div className="row">
                    <div className="col-lg-6">
                    <div className={styles.registerLeft}>
                            <h4 className="text-white text-32 fw-semibold">
                              Expenses
                            </h4>

                            <p className="text-16 text-white opacity-75">
                              You can provide all the information here regarding
                              the contract; you just need to fill in all the
                              details here.
                            </p>
                        
                      <div
                        className="col-lg-11 col-12"
                      >
                        <figure>
                          <img src={Occupations} alt="" className="img-fluid" />
                        </figure>
                      </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className={styles.signUPContainer}>
                        <div className={styles.registerRight}>
                          
                          <div className={styles.formGroup}>
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>Are Travel Expense Reimbursed?</label>
                              <i>
                                <img className="customeTolImg" src={tip} alt="" />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT
                                    .TRAVEL_EXPENSE_REIMBURSED
                                }
                              />
                            </div>
                            <div className={styles.rateradio}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  checked={isExpenseReimbursed}
                                  onChange={() =>
                                    onChange(true, "isExpenseReimbursed")
                                  }
                                />
                                <label className="form-check-label text-white">Yes</label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault2"
                                  id="flexRadioDefault2"
                                  checked={!isExpenseReimbursed}
                                  onChange={() =>
                                    onChange(false, "isExpenseReimbursed")
                                  }
                                />
                                <label className="form-check-label text-white">No</label>
                              </div>
                            </div>
                            {isExpenseReimbursed ? (
                              <>
                                <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                                  {" "}
                                  <div className="topTipHover">
                                    <label>Daily Per USD?</label>
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .DAILY_PER_DIEM
                                      }
                                    />
                                  </div>
                                  <input
                                    type="number"
                                    name="dailyPerDiem"
                                    placeholder="Enter daily per USD"
                                    className="form-control rounded-pill h-48"
                                    value={dailyPerDiem}
                                    onChange={(e) =>
                                      onChange(
                                        Number(e.target.value),
                                        "dailyPerDiem"
                                      )
                                    }
                                    onBlur={formIk.handleBlur}
                                  />
                                  {formIk.touched.dailyPerDiem &&
                                    formIk.errors.dailyPerDiem ? (
                                    <div className={styles.error}>
                                      {formIk.errors.dailyPerDiem}
                                    </div>
                                  ) : null}
                                </div>
                                <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                                  {" "}
                                  <div className="topTipHover">
                                    <label>Travel Day Per USD?</label>{" "}
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .TRAVEL_DAY_PER_DIEM
                                      }
                                    />
                                  </div>
                                  <input
                                    type="number"
                                    name="travelDayPerDiem"
                                    placeholder="Enter Travel day per USD"
                                    className="form-control rounded-pill h-48"
                                    value={travelDayPerDiem}
                                    onChange={(e) =>
                                      onChange(
                                        Number(e.target.value),
                                        "travelDayPerDiem"
                                      )
                                    }
                                  />
                                  {formIk.touched.travelDayPerDiem &&
                                    formIk.errors.travelDayPerDiem ? (
                                    <div className={styles.error}>
                                      {formIk.errors.travelDayPerDiem}
                                    </div>
                                  ) : null}
                                </div>
                                <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                                  {" "}
                                  <div className="topTipHover">
                                    <label>Hotel Allowance Per Day?</label>{" "}
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .HOTEL_ALLOWANCE_PER_DAY
                                      }
                                    />
                                  </div>
                                  <input
                                    type="number"
                                    name="hotelAllowance"
                                    placeholder="Enter hotel allowance"
                                    className="form-control rounded-pill h-48"
                                    value={hotelAllowance}
                                    onChange={(e) =>
                                      onChange(
                                        Number(e.target.value),
                                        "hotelAllowance"
                                      )
                                    }
                                  />
                                  {formIk.touched.hotelAllowance &&
                                    formIk.errors.hotelAllowance ? (
                                    <div className={styles.error}>
                                      {formIk.errors.hotelAllowance}
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-4 text-start my-3 btn-hover-effect">
                          <h6
                            onClick={() => handleGoBack()}
                            className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                          >
                            <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">Back</span>
                            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                          </h6>
                        </div>

                        <div className="col-4">
                          <SaveAsDraftButton />
                        </div>
                        <div className="col-4 text-end my-3 btn-hover-effect">
                          <button
                            type="submit"
                            className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                          >
                            <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                              Next
                            </span>
                            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                              <img src={whitearrow} alt="" />
                            </i>
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                  {/* <div className={styles.regisFooter}>
                  <div className="" onClick={() => handleGoBack()}>
                    <span>‹ Back</span>
                  </div>
                  <div className={styles.DraftBtns}>
                    <SaveAsDraftButton />
                  </div>
                  <div className="">
                    <button type="submit">Continue ›</button>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddExpense;
