import React from "react";
import styles from "../styles/style.module.css";
import dummy from "../../../assets/images/dummy.jpeg";

const InterviewQuestion = () => {
  return (
    <div className={styles.AboutEdit}>
      <h4 className="text-20 fw-bold  font-inter mb-0">Interview Question</h4>
      <ul className="row align-items-center mt-3">
        <li className="col-md-6 col-12 d-flex align-items-center mb-3">
          <span>
            <img
              src={dummy}
              alt=""
              width={40}
              height={40}
              className="img-fluid rounded-circle"
            />
          </span>
          <div className="ps-3">
            <h6 className="text-14 mb-0">
              Willing to work more than 60 miles from home city
            </h6>
            <p className="text-12 mb-0">
              Willing to work more than 60 miles from home city
            </p>
          </div>
        </li>

        <li className="col-md-6 col-12 d-flex align-items-center mb-3">
          <span>
            <img
              src={dummy}
              alt=""
              width={40}
              height={40}
              className="img-fluid rounded-circle"
            />
          </span>
          <div className="ps-3">
            <h6 className="text-14 mb-0">
              Willing to work more than 60 miles from home city
            </h6>
            <p className="text-12 mb-0">
              Willing to work more than 60 miles from home city
            </p>
          </div>
        </li>
        <li className="col-md-6 col-12 d-flex align-items-center mb-3">
          <span>
            <img
              src={dummy}
              alt=""
              width={40}
              height={40}
              className="img-fluid rounded-circle"
            />
          </span>
          <div className="ps-3">
            <h6 className="text-14 mb-0">
              Willing to work more than 60 miles from home city
            </h6>
            <p className="text-12 mb-0">
              Willing to work more than 60 miles from home city
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default InterviewQuestion;
