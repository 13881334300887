import React, { useEffect, useState } from "react";
import styles from "../../../styles/style.module.css";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { Dropdown, FormControl } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import { locationSchema } from "../../../validations";
import { getCitizenshipList } from "../../../../../feature/auth/signup/api";
import {
  setActiveStep,
  setLocation,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { IOption } from "../../../../../Interfaces";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { cities, states, streets } from "../../Constants/locationData";
import { toast } from "react-toastify";
import { SaveAsDraftButton } from "../../Components";
const AddLocation: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { location } = useSelector((state: RootState) => state.addContract);
  const { selectedCity, selectedCountry, selectedState, selectedStreet } =
    location;
  const formik = useFormik({
    initialValues: {
      selectedCountry,
      selectedStreet,
      selectedState,
      selectedCity,
    },
    validationSchema: locationSchema,
    onSubmit: (values) => { },
  });

  const filteredOptions = location.countries.filter((option: IOption) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const getCitizenshipData = async () => {
      const response = await getCitizenshipList();
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      if (response.data.length > 0) {
        const usCountry = response.data.find(
          (x) => x.code.toLowerCase() === "us"
        );

        if (usCountry) {
          formik.setFieldValue("selectedCountry", usCountry.name);
          dispatch(
            setLocation({
              ...location,
              selectedCountry: usCountry,
              countries: response.data,
              states,
              cities,
              streets,
            })
          );
        }
      }
    };

    getCitizenshipData();
  }, []);

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_KEY_INFORMATION));
  };

  const handleGoToNextStep = () => {
    if (!selectedCountry) {
      toast.error("Country is required!");
      return;
    } else if (!selectedState) {
      toast.error("State is required!");
      return;
    } else if (!selectedCity) {
      toast.error("City is required!");
      return;
    } else if (!selectedStreet) {
      toast.error("Street is required!");
      return;
    } else {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_RATES));
    }
  };

  const onInputChange = (value: IOption, key: string) => {
    if (value.label === "") {
      return;
    }
    dispatch(setLocation({ ...location, [key]: value }));
  };

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className={styles.registerOuter}>
            <div className="container">
              <div className={styles.registerBord}>
                <div className="row pt-2 h-100 align-items-start">
                  <div className="col-lg-6">
                  <div className={styles.registerLeft}>
                          <h4 className="text-white text-32 fw-semibold">
                            Address Of The Job Site
                          </h4>
                          <p className="text-16 text-white opacity-75">
                            Where will the work take place?
                          </p>
                    
                    <div className="col-lg-11 col-12"
                    >
                      <figure>
                        <img src={Occupations} alt="" className="img-fluid" />
                      </figure>
                    </div>
                  </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className={styles.signUPContainer}>
                      <div className={styles.registerRight}>
                        
                        <form>
                          <div className="">
                            <p className="text-white bg-gradient p-2 border text-14 rounded-3">
                              <strong>Note :</strong> This address will serve as your default business address moving forward.
                            </p>
                          </div>
                          <div className={styles.ScrollDiv}>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>
                                Country
                              </label>
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.COUNTRY}
                              />
                            </div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                {selectedCountry
                                  ? selectedCountry.label
                                  : "Please select an option"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <FormControl
                                  autoFocus
                                  className="mx-3 my-2 w-auto"
                                  placeholder="Search..."
                                  onChange={handleSearch}
                                  value={searchTerm}
                                />

                                {filteredOptions.length === 0 && (
                                  <Dropdown.Item disabled>
                                    No options found
                                  </Dropdown.Item>
                                )}

                                {filteredOptions?.map(
                                  (option, index: number) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        onInputChange(option, "selectedCountry")
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>Street</label>
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.STREET}
                              />
                            </div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                {selectedStreet
                                  ? selectedStreet.label
                                  : "Please select an option"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {location?.streets?.map(
                                  (option, index: number) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        onInputChange(option, "selectedStreet")
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>State</label>
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.STATE}
                              />
                            </div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                {selectedState
                                  ? selectedState.label
                                  : "Please select an option"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {location?.states?.map(
                                  (option, index: number) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        onInputChange(option, "selectedState")
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>City</label>
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.CITY}
                              />
                            </div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                {selectedCity
                                  ? selectedCity.label
                                  : "Please select an option"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {location?.cities?.map(
                                  (option, index: number) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        onInputChange(option, "selectedCity")
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                         
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "0px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>Zipcode</label>
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.STREET}
                              />
                            </div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                {selectedStreet
                                  ? selectedStreet.label
                                  : "Please select an option"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {location?.streets?.map(
                                  (option, index: number) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        onInputChange(option, "selectedStreet")
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                      <div className="col-4 text-start my-3 btn-hover-effect">
                        <h6
                          onClick={() => handleGoBack()}
                          className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                            Back
                          </span>
                          <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                            <img src={blackarrow} alt="" />
                          </i>
                        </h6>
                      </div>

                      <div className="col-4">
                        <SaveAsDraftButton />
                      </div>
                      <div className="col-4 text-end my-3 btn-hover-effect">
                        <h6
                          onClick={handleGoToNextStep}
                          className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                            Next
                          </span>
                          <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </h6>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLocation;
