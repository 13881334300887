import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import security from "../../../assets/images/security.svg";
import EditFinancialInfoModal from "../modals/EditFinancialInfoModal";
import { PayRateInterface } from "../interface";

const FinancialInfo: React.FC<PayRateInterface> = ({ rates, refresh }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Financial Information*
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img
              src={penbl}
              className="cursor-pointer ms-auto"
              alt=""
              onClick={openModal}
            />
          </span>
        </div>
      </div>
      <div className={styles.personalinfo}>
        <ul className="row align-items-center">
          {/* <li className="col-xl-4 col-6 mb-3">
            <span className="">Pay Rate</span>
            <label className="clamp clamp1">${rates?.baseRate}</label>
          </li> */}
          <li className="col-xl-4 col-6 mb-3">
            <span className="">Base Rate (0-40 hours)</span>
            <label className="clamp clamp1">${rates?.baseRate}</label>
          </li>
          <li className="col-xl-4 col-6 mb-3">
            <span>Overtime Rate (41+ hours)</span>
            <label className="clamp clamp1">${rates?.overTimeRate}</label>
          </li>
          <li className="col-xl-4 col-6 mb-3">
            <span>Sunday and Holiday Rate</span>
            <label className="clamp clamp1">${rates?.doubleTimeRate}</label>
          </li>
          <li className="col-xl-4 col-6 mb-3">
            <span>Flat Rate (All-inclusive, no overtime)</span>
            <label className="clamp clamp1">${rates?.flatRate}</label>
          </li>
        </ul>
      </div>
      <div className={styles.note}>
        <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
          <div className="col-lg-1 col-2 text-center">
            <i>
              <img src={security} alt="" />
            </i>
          </div>
          <div className="col-lg-11 col-10">
            <p className="text-14 fw-medium mb-0">
              Please provide your financial information securely to facilitate
              payments. Your security is extremely important to us, and only
              Automate America Financial Administration will have access to this
              information.
            </p>
          </div>
        </div>
      </div>
      <EditFinancialInfoModal
        showModal={showModal}
        closeModal={closeModal}
        title="Edit Finantional Info"
        rates={rates}
        refresh={refresh}
      />
    </div>
  );
};

export default FinancialInfo;
