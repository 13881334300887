import React from "react";
import { Dropdown, FormControl, ModalBody, ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import blackarrow from "../../../assets/images/blackarrow.svg";
import whitearrow from "../../../assets/images/whitearrow.svg";
import upload from "../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
}

const EditCompanyAdminContModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
}) => {
  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form>
        <ModalBody>
          <div className="addModalPro">
            <div className="row">              
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name of Facility"
                    />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                    />
                  </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Phone Number*</label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Phone Number*"
                    />

                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Email ID *</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email address*"
                    />

                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-lg-4 p-3">
              <img
                src={blackarrow}
                alt=""
                width={15}
                height={15}
                style={{ transform: "rotate(-180deg)" }}
              />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Save
            </span>
            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-lg-4 p-3">
              <img src={whitearrow} alt="" width={15} height={15} />
            </i>
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditCompanyAdminContModal;
