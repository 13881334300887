import React, { FC, useState } from "react";
import styles from "../../../styles/style.module.css";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { Dropdown, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Select from "react-select";
import { RootState } from "../../../../../redux/store";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import {
  setActiveStep,
  setOccupation,
} from "../../../../../redux/reducers/addContract";
import { toast } from "react-toastify";
import { postMasterType } from "../../../api";
import { MESSAGES } from "../../../../../utils/messages";
import { APIResponseError } from "../../../interface";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import storage from "../../../../../utils/storage";
import { occupationDropdownSchema } from "../../../validations";
import CreatableSelect from "react-select/creatable";

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

const ChooseOccupations: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = storage.getToken();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [value, setValue] = useState<Option | null>();
  const [ids, setIds] = useState<string[]>([]); 

  const { contractType, occupation, occupationsList } = useSelector(
    (state: RootState) => state.addContract
  );
  const [options, setOptions] = useState(occupationsList);

  const formik = useFormik({
    initialValues: {
      primaryOccupation: occupation.primaryOccupation?.value || "",
    },
    validationSchema: occupationDropdownSchema,
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_DESCRIPTION));
    },
  });

  const handleSubmitCustomOccupation = async (
    newValue?: any,
    type?: string
  ) => {
    const payload = {
      type: MASTER_TYPE.OCCUPATIONS,
      name: newValue || searchTerm,
      alias: newValue || searchTerm,
    };

    toast.promise(postMasterType(payload), {
      pending: {
        render() {
          return MESSAGES.POSTING_CONTRACTS.VERIFYING;
        },
      },
      success: {
        render(newOccupation) {
          const newId = newOccupation?.data?.data?._id || newValue;
          if (type === "PRIMARY_OCCUPATION") {
            const _obj: any = {
              label: newOccupation?.data?.data?.name || newValue,
              value: newId,
            };

            console.log('====================================');
            console.log(newOccupation, "newOccupationnewOccupation");
            console.log('====================================');

            const newArr = [...occupation.secondaryOccupations, _obj];
            dispatch(setOccupation({ ...occupation, secondaryOccupations: newArr }));
            return MESSAGES.POSTING_CONTRACTS.ADDING;
          }

          formik.setFieldValue(
            "primaryOccupation",
            newOccupation.data.data.name
          );
          setFilterValue("");
          setSearchTerm("");
          
          setIds((prevIds) => [...prevIds, newId]);
          dispatch(
            setOccupation({
            
              ...occupation,
              primaryOccupation: {
                label: newOccupation.data.data.name,
                value: newOccupation.data.data._id,
              },
              
              ids: [...ids, newId], 
            })
            
          );

          return MESSAGES.POSTING_CONTRACTS.ADDING;
        },
      },
      error: {
        render(error) {
          let errorMesage = error as APIResponseError;
          return errorMesage?.data?.data?.message;
        },
      },
    });
  };

  const handleGoBack = () => {
    navigate(APP_ROUTES.ADD_CONTRACT);
  };

  const handleOccupationChange = (value: any, key: string) => {
    console.log(value, "<<<< skldflksdfslkdjfklasjdf >>>>" + key);

    dispatch(setOccupation({ ...occupation, [key]: value }));
    if (key === "primaryOccupation") {
      formik.setFieldValue(key, value.value);
    } else {
      formik.setFieldValue(key, value);
    }
  };

  const handleCreate = (inputValue: string) => {

    handleSubmitCustomOccupation(inputValue, "PRIMARY_OCCUPATION")


    // setTimeout(() => {
    //   const newOption = createOption(inputValue);
    //   setOptions((prev) => [...prev, newOption]);
    //   setValue(newOption);
    // }, 1000);
  };

  const primaryOccOptions =
    filterValue && filterValue !== ""
      ? occupationsList.filter((x) =>
        x.label.toLowerCase().includes(filterValue.toLowerCase())
      )
      : occupationsList;

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
            <form onSubmit={formik.handleSubmit}>
              <div style={{ position: "relative" }}>
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row align-items-start">
                      <div className="col-lg-6">
                        <div className={styles.registerLeft}>
                          <h4 className="text-white text-32 fw-semibold">
                            Who Do You Need?
                          </h4>
                          <p className="text-16 text-white opacity-75">
                            What is the Primary and Secondary Occupations of
                            <br />
                            the person that you are looking for?
                          </p>
                          <div className="col-lg-11 col-12">
                            <figure>
                              <img
                                src={Occupations}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className={styles.registerBord}>
                          <div className={styles.signUPContainer}>
                            <div>
                              {contractType === "directJob" && (
                                <>
                                  <p className="text-16 text-white opacity-75">
                                    {" "}
                                    Top Occupations (Click to Select)
                                  </p>
                                  <p className="text-16 text-white opacity-75">
                                    {" "}
                                    Robot Programmer PLC Programmer Controls
                                    Engineer Maintenance Technician Senior
                                    Controls Engineer Automation Engineer
                                    Automation Technician Machine Tool Builder
                                    Machine Tool Electrician Manufacturing
                                    Engineer Robot Technician Electrician
                                    Mechanical Designer Process Engineer
                                  </p>
                                </>
                              )}
                            </div>

                            <div className={styles.registerRight}>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="topTipHover">
                                  <label className={styles.starlabel}>
                                    Primary Occupation
                                  </label>
                                  <i className="">
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>

                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT
                                        .PRIMARY_OCCUPATION
                                    }
                                  />
                                </div>
                                {/* <i className="fa-duotone fa-asterisk"></i> */}
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    id="dropdown-basic"
                                  >
                                    {occupation.primaryOccupation
                                      ? occupation.primaryOccupation.label
                                      : "Please select an option"}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <div className="">
                                      <FormControl
                                        autoFocus
                                        className="mx-3 my-2 w-auto"
                                        placeholder="Search..."
                                        onChange={(e) =>
                                          setFilterValue(e.target.value)
                                        }
                                        value={filterValue}
                                      />
                                    </div>

                                    {/* {token && ( */}
                                    <div className={styles.addOccuatin}>
                                      <div className="col-8">
                                        <input
                                          type="text"
                                          placeholder="Add Other"
                                          name="customOccupation"
                                          value={searchTerm}
                                          onChange={(e) =>
                                            setSearchTerm(e.target.value)
                                          }
                                          onBlur={formik.handleBlur}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              handleSubmitCustomOccupation();
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {/* )} */}

                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        // overflowY: "scroll",
                                      }}
                                    >
                                      {primaryOccOptions.length === 0 ? (
                                        <Dropdown.Item disabled>
                                          No options found
                                        </Dropdown.Item>
                                      ) : (
                                        [
                                          {
                                            label: "Select primary occupation",
                                            value: "",
                                          },
                                          ...primaryOccOptions,
                                        ].map((option, index: number) => (
                                          <>
                                            <Dropdown.Item
                                              key={index}
                                              onClick={() =>
                                                handleOccupationChange(
                                                  option,
                                                  "primaryOccupation"
                                                )
                                              }
                                            >
                                              {option.label}
                                            </Dropdown.Item>
                                          </>
                                        ))
                                      )}

                                  
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                                {formik.errors.primaryOccupation &&
                                  formik.touched.primaryOccupation && (
                                    <div className={styles.error}>
                                      {formik.errors.primaryOccupation}
                                    </div>
                                  )}
                              </div>

                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="topTipHover">
                                  <label>Secondary Occupations.........</label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT
                                        .SECONDARY_OCCUPATION
                                    }
                                  />
                                </div>
                                <CreatableSelect
                                  isMulti
                                  onCreateOption={handleCreate}
                                  name="colors"
                                  options={occupationsList as any}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    handleOccupationChange(
                                      val,
                                      "secondaryOccupations"
                                    )
                                  }
                                  value={occupation.secondaryOccupations}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-4 text-start my-3 btn-hover-effect">
                            <h6
                              onClick={() => handleGoBack()}
                              className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton mb-0"
                            >
                              <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                <img src={blackarrow} alt="" />
                              </i>
                              <span className="px-lg-4 px-2 ps-3 text-12 fw-semibold d-block">
                                Back
                              </span>
                            </h6>
                          </div>

                          <div className="col-4 text-end my-3 btn-hover-effect">
                            <button
                              type="submit"
                              className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 mb-0"
                            >
                              <span className="px-lg-4 px-2 pe-lg-3 text-12 fw-semibold d-block">
                                Next
                              </span>
                              <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                <img src={whitearrow} alt="" />
                              </i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseOccupations;
