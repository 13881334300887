import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import EditCompanyAdminContModal from "../modals/EditCompanyAdminContModal";

const CompanyAdminContact = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Company Administration Contacts
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img src={penbl} className="cursor-pointer ms-auto" alt="" onClick={openModal}  />
          </span>
        </div>
      </div>
      <div className={styles.personalinfo}>
        <ul className="row align-items-center">
          <li className="col-xl-4 col-6">
            <span className="">Name</span>
            {/* <input
        className={styles.editable}
        name={PROFILE.FORMIK.name}
        onChange={profileDetailsFormik.handleChange}
        value={profileDetailsFormik.values?.name}
      /> */}
           <label className="clamp clamp1">Company Name</label>
          </li>
        </ul>
        <ul className="row align-items-center">
          <li className="col-xl-4 col-6">
            <span className="">Email</span>
            {/* <input
        className={styles.editable}
        name={PROFILE.FORMIK.name}
        onChange={profileDetailsFormik.handleChange}
        value={profileDetailsFormik.values?.name}
      /> */}
            <label className="clamp clamp1">Chandigarh</label>
          </li>
          <li className="col-xl-4 col-6">
            <span className="">Phone</span>
            {/* <input
        className={styles.editable}
        name={PROFILE.FORMIK.name}
        onChange={profileDetailsFormik.handleChange}
        value={profileDetailsFormik.values?.name}
      /> */}
            <label className="clamp clamp1">Mall Road CHandigarh</label>
          </li>
          <li className="col-xl-4 col-6">
            <span className="">City</span>
            {/* <input
        className={styles.editable}
        name={PROFILE.FORMIK.name}
        onChange={profileDetailsFormik.handleChange}
        value={profileDetailsFormik.values?.name}
      /> */}
            <label className="clamp clamp1">Andre.g@gmail.com</label>
          </li>
        </ul>
        <div className="">
          <h4 className="text-14 fw-semibold text-blue">+ Add more</h4>
        </div>
      </div>
      <EditCompanyAdminContModal showModal={showModal} closeModal={closeModal} title="Edit Company Administration Contacts"/>
    </div>
  );
};

export default CompanyAdminContact;
