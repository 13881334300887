import React from "react";
import styles from "../../components/work/styles/style.module.css";
import { useDispatch, useSelector } from "react-redux";
import group from "../../assets/images/Group1686558045.png";
import whitearrow from "../../assets/images/whitearrow.svg";
import blackarrow from "../../assets/images/blackarrow.svg";
import { RootState } from "../../redux/store";
import { setLegalDetails } from "../../redux/reducers/addContract";

const Post = (props: any) => {
  const dispatch = useDispatch();
  const { legalDetails } = useSelector((state: RootState) => state.addContract);
  const { isAutomateAmerica, isAnonymous } = legalDetails;

  const onInputChange = (isAutomateAmerica: boolean, isAnonymous: boolean) => {
    dispatch(
      setLegalDetails({ ...legalDetails, isAutomateAmerica, isAnonymous })
    );
  };

  const submitContract = () => {
    props.publishContract();
  };

  return (
    <div className="py-3">
      <div className="col-12">
        <img src={group} alt="" className="img-fluid mx-auto" />
      </div>

      <div className="row justify-content-center mt-4 mx-0">
        <div className={styles.ratedRadio}>
          <div className="p-3 bg-lightblue mb-4">
            <label className="text-24 fw-semibold text-center text-black mb-3">
              Post As Own company
            </label>
            <div
              className={styles.rateradio}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="postingOption"
                  checked={isAutomateAmerica && !isAnonymous}
                  onChange={() => onInputChange(true, false)}
                />
                <label
                  htmlFor="isAutomateAmerica"
                  className="text-20 text-center text-black"
                >
                  yes
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="postingOption"
                  // checked={isAutomateAmerica && !isAnonymous}
                  // onChange={() => onInputChange(true, false)}
                />
                <label
                  htmlFor="isAutomateAmerica"
                  className="text-20 text-center text-black"
                >
                  No
                </label>
              </div>
            </div>
          </div>
          <div className={styles.ratedRadio}>
            <div className="p-3 bg-lightblue">
              <label className="text-24 fw-semibold text-center text-black mb-3">
                Post As Anonymous
              </label>
              <div
                className={styles.rateradio}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="postingOption"
                    id="isAnonymous"
                    checked={!isAutomateAmerica && isAnonymous}
                    onChange={() => onInputChange(false, true)}
                  />
                  <label
                    htmlFor="isAnonymous"
                    className="text-20 text-center text-black"
                  >
                    Remote
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="postingOption"
                    id="isAnonymous"
                    // checked={!isAutomateAmerica && isAnonymous}
                    // onChange={() => onInputChange(false, true)}
                  />
                  <label
                    htmlFor="isAnonymous"
                    className="text-20 text-center text-black"
                  >
                    Onsite
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="postingOption"
                    id="isAnonymous"
                    // checked={!isAutomateAmerica && isAnonymous}
                    // onChange={() => onInputChange(false, true)}
                  />
                  <label
                    htmlFor="isAnonymous"
                    className="text-20 text-center text-black"
                  >
                    Hybrid
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 my-3 btn-hover-effect text-end">
          <div className="row mx-0 my-3  justify-content-end">
            <div className="col-5 btn-hover-effect">
              <h6
                // onClick={onClose}
                className="btn bg-white arrowyellow border-black border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
              >
                <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                  <img src={blackarrow} alt="" />
                </i>
                <span className="px-lg-4 px-2 text-12 fw-semibold">
                  Not Now
                </span>
              </h6>
            </div>
            <div className="col-5 btn-hover-effect">
              <button
                type="button"
                onClick={() => submitContract()}
                className="btn bg-white arrowblue border-black border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold">Submit</span>
                <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                  <img src={whitearrow} alt="" />
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Post;
