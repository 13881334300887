import React, { FC, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import storage from '../../../../../utils/storage';
import { useNavigate } from 'react-router-dom';
import { AddContractRootState } from '../../../../../models';
import { saveContract, updateContract } from '../../../api';
import { WORK_STATUS_TYPE } from '../../../../../utils/enums/status';
import { APP_ROUTES } from '../../../../../utils/routes/AppRoutes';
import { APIResponseError } from '../../../interface';
import SaveAsDraftAuthErrorDialog from './SaveAsDraftAuthErrorDialog/SaveAsDraftAuthErrorDialog';
import { setContractId } from '../../../../../redux/reducers/addContract';

const SaveAsDraftButton: FC = () => {
  const { addContract, authentication } = useSelector((state: RootState) => state);
  const { isAuthenticated } = authentication;
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const contractStatus = WORK_STATUS_TYPE.DRAFT;
  const token = storage.getToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmitContract = async () => {
    if (!isAuthenticated && !token) {
      setShowErrorDialog(true);
      return;
    }
    await saveContractAsDraft();
  };

  const saveContractAsDraft = async () => {
    const savedContractId = addContract._id;
    const payload = new AddContractRootState({
      ...addContract,
      contractStatus,
    }).serialize();

    if (savedContractId && savedContractId !== '') {
      try {
        const response = await updateContract(payload, savedContractId);

        if (response && contractStatus === WORK_STATUS_TYPE.DRAFT) {
          Swal.fire({
            title: 'Contract updated successfully!',
            text: 'Your contract has been updated successfully!',
            icon: 'success',
            confirmButtonText: 'View Dashboard',
            cancelButtonText: 'Continue',
            showCancelButton: true,
            customClass: {
              confirmButton: 'swal-custom-button', // Custom class for confirm button
              cancelButton: 'swal-cancel-button', // Custom class for cancel button
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              });
              navigate(APP_ROUTES.HOME);
            }
          });
        }
      } catch (error) {
        const errorMessage = error as APIResponseError;
        Swal.fire({
          title: 'Error',
          text: errorMessage?.data?.data?.message || 'An error occurred.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } else {
      try {
        const response = await saveContract(payload);
        const savedContract = response.data;

        dispatch(setContractId(savedContract._id));
        //storage.clearSavedContract();

        if (contractStatus === WORK_STATUS_TYPE.DRAFT) {
          Swal.fire({
            title: 'Contract saved as Draft',
            text: 'Your contract has been saved as a draft successfully!',
            icon: 'success',
            confirmButtonText: 'View Dashboard',
            cancelButtonText: 'Close',
            showCancelButton: true,
            customClass: {
              confirmButton: 'swal-custom-button', // Custom class for confirm button
              cancelButton: 'swal-cancel-button', // Custom class for cancel button
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              });
              navigate(APP_ROUTES.HOME);
            }
          });
        }
      } catch (error) {
        const errorMessage = error as APIResponseError;
        Swal.fire({
          title: 'Error',
          text: errorMessage?.data?.data?.message || 'An error occurred.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const onClick = () => {
    Swal.fire({
      title: 'Do you want to save the changes as a Draft?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
    }).then((result) => {
      // Run handleSubmitContract only when the "Save" button is clicked
      if (result.isConfirmed) {
        handleSubmitContract();
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
      // No need to do anything on cancel, as the modal will just close
    });
  };

  return (
    <>
      <div className="col-12 text-end my-3 btn-hover-effect">
        <SaveAsDraftAuthErrorDialog
          show={showErrorDialog}
          onHide={() => setShowErrorDialog(false)}
        />
        <h6
          onClick={onClick}
          className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2"
          style={{zIndex: "0"}}
        >
          <span className="px-lg-4 px-2 d-block text-12 fw-semibold py-1">
            Save as Draft
          </span>
        </h6>
      </div>
    </>
  );
};

export default SaveAsDraftButton;
