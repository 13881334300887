import React, { useState } from "react";
import { JobResponse } from "../interface";
import styles from "../../common/css/styles/style.module.css";
import loc from "../../../assets/images/loc.png";
import map from "../../../assets/images/map.png";
import moment from "moment";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import EligibiltyModal from "../../common/modals/eligibilty/page";
interface UserDetailsProps {
  user: JobResponse | null;
  workDetails: JobResponse | null
}
const UserDetails: React.FC<UserDetailsProps> = ({ user,workDetails }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");
  const handleShowModal = (value: any) => {
    if (value.eligibilityQuestions.length > 0 || value.interviewQuestions.length > 0) {
      setShowModal(true);
      setWorkId(value._id);
    } else {
      navigate(`${APP_ROUTES.JOBDETAIL}/${value._id}`);
    }
    
  };
  if (!user) {
    return <div className="col-md-8">loading.....</div>;
  }

  return (
    <>
     {showModal && (
        <EligibiltyModal onClose={() => setShowModal(false)} workId={workId} />
      )}
      <div className="col-lg-8">
        <div className={styles.RightOpportunity}>
          <div className={styles.ContarctOuter}>
            <div className={styles.opprBox}>
              <div className={styles.PendingCOnatctList}>
                <h6 className={styles.name_robo}>{workDetails?.occupation?.name}</h6>
                <span className={styles.Hrrate}>{workDetails?.planType}</span>
                <div className={styles.Loca}>
                  <p className={styles.Locate}>
                    <img src={loc} alt="" /> {workDetails?.address}
                  </p>
                </div>
                <div className={styles.PenigSkilRate}>
                  <ul>
                    <li>
                      <span>TOP SKILL</span>
                      <label>{workDetails?.rates?.baseRate}</label>
                    </li>
                    <li>
                      <span>MANUFACTURER</span>
                      <label>{workDetails?.rates?.overTimeRate}</label>
                    </li>
                    <li>
                      <span>MODEL</span>
                      <label>{workDetails?.rates?.doubleTimeRate}</label>
                    </li>
                  </ul>
                </div>
                <div className={styles.PenigSkilRate}>
                  <h5>Pay Rates</h5>
                  <ul>
                    <li>
                      <span>Base</span>
                      <label>{workDetails?.rates?.baseRate}</label>
                    </li>
                    <li>
                      <span>Overtime</span>
                      <label>{workDetails?.rates?.overTimeRate}</label>
                    </li>
                    <li>
                      <span>Sun/Holidays</span>
                      <label>{workDetails?.rates?.doubleTimeRate}</label>
                    </li>
                  </ul>
                </div>

                <div className="col-md-12">
                  <div className={styles.bordeerBottom}></div>
                  <h5 className={styles.payrate}>Expenses</h5>
                  <div className="row">
                    <div className="col-lg-7 col-sm-12">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className={styles.datesFlex2}>
                            <h6>Daily Per Diam </h6>
                            <p className={styles.dollar}> {workDetails?.expense?.dailyPerDiem}</p>
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                          <div className={styles.datesFlex2}>
                            <h6>Airfare Allowance</h6>
                            <p className={styles.dollar}> {workDetails?.expense?.airfareAllowance}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          
                {/* <div className="col-md-12">
                  <div className={styles.bordeerBottom}></div>
                  <h5 className={styles.payrate}>
                    Tools, Machines and equipment
                  </h5>
                  <div className={styles.PenigSkil}>
                    <ul>
                      <li>Lorem Ipsum</li>
                      <li>Figma</li>
                      <li>Adobe</li>
                    </ul>
                  </div>
                </div> */}

                <div className={styles.bordeerBottom}></div>
                <h5 className={styles.payrate}>SCHEDULE</h5>
                <div className="row">
                  <div className="col-lg-7 col-sm-12">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Start date </h6>
                          <p className="mb-0">
                            {" "}
                            {moment(workDetails?.times?.startDate).format(
                              "MMM DD, YYYY"
                            )}
                          </p>
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Duration</h6>
                          <p className="mb-0">
                      {    workDetails?.times?.duration}
                          </p>
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>People Needed </h6>
                          <p className="mb-0"> {workDetails?.times?.noOfPepole}</p>
                          <i className="far fa-calendar-alt"> </i>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Day Shift </h6>
                          <p className="mb-0">
                            {workDetails?.times?.shift}
                          </p>
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Planned Hrs Per Day </h6>
                          <p className="mb-0">
                            {" "}
                          {  workDetails?.times?.plannedHoursPerDay}
                          </p>
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Planned Hrs Per Week </h6>
                          <p className="mb-0"> {workDetails?.times?.plannedDaysPerWeek}</p>
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.bordeerBottom}></div>
                  <div className={styles.PenigSkil} style={{marginTop: "15px"}}>
                    <h5>Job Description</h5>
                    <div
                      className={styles.Peras}
                      dangerouslySetInnerHTML={{
                        __html: workDetails?.description || "No description available",
                      }}
                    />
                  </div>
                  <div className={styles.PenigSkilRate22}>
                    <h5>Software Licenses</h5>
                    <div className={styles.SoftWareLicenece}>
                      <div className="row align-items-center w-100">
                        <div className="col-lg-6">
                          <ul className={`nav ${styles.TaglinesButton}`}>
                          { workDetails?.softwareLicence.map((license:any) => (
            <li key={license?.softwareLicenceId}>
              <span
                className={
                  license.isMandatory
                    ? styles.blueButtons
                    : license.isGoodToHave
                    ? styles.greenButtons
                    : styles.orangeButtons
                }
              >
                {license?.softwareLicenceDetails?.name}
              </span>
            </li>
          ))}
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul className={`nav ${styles.Taglines}`}>
                            <li>
                              <span className={styles.greenBOx}></span>Good to
                              have
                            </li>
                            <li>
                              <span className={styles.blueBOx}> </span>Mandatory
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className={styles.bordeerBottom}></div>
                    <h5 className={styles.payrate}>Procedure and Policies</h5>
                    <p className={styles.Peras}>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using.
                    </p>
                  </div>

                  <div className="col-md-12">
                    <div className={styles.bordeerBottom}></div>
                    <div className={styles.flexbtns}>
                      <button
                        type="button"
                        className={`btn ${styles.commonbtn} ${styles.applybtn}`}
                        onClick={() => handleShowModal(user)}
                      >
                        Apply now ›
                      </button>
                      <button
                        type="button"
                        className={`btn ${styles.commonbtn} ${styles.sharebtn}`}
                      >
                        Share{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bordeerBottom}></div>
          {/* <div className={styles.mapView}>
            <img src={map} alt="" className="img-fluid" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default UserDetails;

// <div>
//             <strong>Software Licenses</strong>
//             {workDetails.softwareLicence.map((license) => (
//               <span
//                 key={license.name}
//                 className={`badge mx-1 ${
//                   license.type === 'Good to have' ? 'bg-success' : 'bg-primary'
//                 }`}
//               >
//                 {license.name}
//               </span>
//             ))}
//           </div>
