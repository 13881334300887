import React, { useEffect, useState } from "react";
import { updateUserProfile } from "../../../home/api";
import { getMasterTypes } from "../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../utils/enums/masterTypes.enum";
import { IOption } from "../../../../Interfaces";
import { Dropdown, FormControl, Modal, ModalFooter } from "react-bootstrap";
import { postMasterType } from "../../../work/api";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../../utils/messages";
import { EducationDetails } from "../../../editprofile/interface";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import styles from "../styles/style.module.css";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  educationDetails: EducationDetails[];
  refresh: () => void;
  handleEdit: (updatedEducation: EducationDetails) => void;
  initialData: EducationDetails | null;
  indexNumber: number | null;
}

const EducationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  educationDetails = [],
  refresh,
  handleEdit,
  initialData,
  indexNumber,
}) => {
  if (!isOpen) return null;
  const [school, setSchool] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [schoolData, setSchoolData] = useState<IOption[]>([]);
  const [degree, setDegree] = useState("");
  const [degreeId, setDegreeId] = useState("");
  const [degreeData, setDegreeData] = useState<IOption[]>([]);
  const [year, setYear] = useState<number | null>(null);
  const [specialize, setSpecialize] = useState("");
  const [specializeId, setSpecializeId] = useState("");
  const [specializeData, setSpecializeData] = useState<IOption[]>([]);
  const [comment, setComment] = useState("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [index, setIndex] = useState<number | null>(null);

  useEffect(() => {
    if (initialData) {
      // If there is initial data, pre-fill the form fields
      setSchool(initialData.schoolDetails?.name || "");
      setSchoolId(initialData.schoolDetails?._id || "");
      setDegree(initialData.degreeDetails?.name || "");
      setDegreeId(initialData.degreeDetails?._id || "");
      setYear(initialData.completionYear || null);
      setSpecialize(initialData.specializationDetails?.name || "");
      setSpecializeId(initialData.specializationDetails?._id || "");
      setComment(initialData.comment || "");
      setIndex(indexNumber);
    }
  }, [initialData]);

  const loadDropDowns = async () => {
    try {
      const schoolResponse = await getMasterTypes(MASTER_TYPE.SCHOOL);
      const degreeRes = await getMasterTypes(MASTER_TYPE.DEGREE);
      const specializationRes = await getMasterTypes(
        MASTER_TYPE.SPECIALIZATIONS
      );
      if (schoolResponse.data && degreeRes.data && specializationRes.data) {
        setSchoolData(
          schoolResponse.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setDegreeData(
          degreeRes.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setSpecializeData(
          specializationRes.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to load school data", error);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleSchoolChange = (label: string, value: string) => {
    setSchool(label);
    setSchoolId(value);
  };

  const handleDegreeChange = (label: string, value: string) => {
    setDegree(label);
    setDegreeId(value);
  };

  const handleSplecializeChange = (label: string, value: string) => {
    setSpecialize(label);
    setSpecializeId(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);
    if (value === "" || !isNaN(numericValue)) {
      setYear(value === "" ? null : numericValue);
    }
  };

  const handleSubmitCustomSchool = async () => {
    const payload = {
      type: MASTER_TYPE.SCHOOL,
      name: searchTerm,
      alias: searchTerm,
    };
    toast.promise(postMasterType(payload), {
      pending: MESSAGES.SCHOOL_ADDED.PENDING,
      success: MESSAGES.SCHOOL_ADDED.SUCCESS,
      error: MESSAGES.SCHOOL_ADDED.ERROR,
    });
  };

  const handleSave = async () => {
    if (schoolId && degreeId && year && specializeId) {
      const newEducation = {
        completionYear: year,
        comment: comment,
        degreeId: degreeId,
        schoolId: schoolId,
        specializationId: specializeId,
      };
      try {
        let updatedProfile:any = {}
        if (
          indexNumber !== null &&
          indexNumber !== undefined &&
          indexNumber >= 0
        ) {
          let educationsData = educationDetails.map((data, ind) => {
            if (indexNumber === ind) {
              return {
                completionYear: newEducation.completionYear,
                comment: newEducation.comment,
                degreeId: newEducation.degreeId,
                schoolId: newEducation.schoolId,
                specializationId: newEducation.specializationId,
              };
            } else {
              return {
                completionYear: data.completionYear,
                comment: data.comment,
                degreeId: data.degreeDetails._id,
                schoolId: data.schoolDetails._id,
                specializationId: data.specializationDetails._id,
              };
            }
          });
           updatedProfile = {
            educations: [...educationsData],
          };
    
        } else {
          let existingData: any = [];
          if (educationDetails?.length) {
            existingData = educationDetails.map((data, ind) => {
              return {
                completionYear: data.completionYear,
                comment: data.comment,
                degreeId: data.degreeDetails._id,
                schoolId: data.schoolDetails._id,
                specializationId: data.specializationDetails._id,
              };
            });
          }
           updatedProfile = {
            educations: [...existingData, newEducation],
          };
        }
        await updateUserProfile(updatedProfile);
        refresh();
      } catch (err) {
        console.log("Occuring error while updating user information", err);
      }
    }
  };

  const filteredSchoolData =
    filterValue && filterValue !== ""
      ? schoolData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : schoolData;

  const filteredDegreeData =
    filterValue && filterValue !== ""
      ? degreeData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : degreeData;

  const filteredSpecializeData =
    filterValue && filterValue !== ""
      ? specializeData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : specializeData;

  return (
    
    <>
    <Modal show={isOpen} onHide={onClose} centered size="lg">
     <Modal.Header closeButton>
       <Modal.Title>{title}</Modal.Title>
     </Modal.Header>
     <form>
       <Modal.Body>       
          <div className="addModalPro">
            <div className="formGroup">
              <label>School Name</label>
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {school || "Please select an option"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="">
                      <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Search..."
                        onChange={(e) => setFilterValue(e.target.value)}
                        value={filterValue}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "scroll",
                      }}
                    >
                      {filteredSchoolData.length === 0 ? (
                        <Dropdown.Item disabled>
                          No options found
                        </Dropdown.Item>
                      ) : (
                        filteredSchoolData.map((option, index: number) => (
                          <>
                            <Dropdown.Item
                              key={index}
                              onClick={() =>
                                handleSchoolChange(
                                  option.label,
                                  option.value
                                )
                              }
                            >
                              {option.label}
                            </Dropdown.Item>
                          </>
                        ))
                      )}
                      <div>
                        <div className="col-8">
                          <input
                            type="text"
                            placeholder="Type school here you want to add..."
                            name="school"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSubmitCustomSchool();
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="formGroup">
              <label>Degree/Certificate Name</label>
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {degree || "Please select an option"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="">
                      <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Search..."
                        onChange={(e) => setFilterValue(e.target.value)}
                        value={filterValue}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "scroll",
                      }}
                    >
                      {filteredDegreeData.length === 0 ? (
                        <Dropdown.Item disabled>
                          No options found
                        </Dropdown.Item>
                      ) : (
                        filteredDegreeData.map((option, index: number) => (
                          <>
                            <Dropdown.Item
                              key={index}
                              onClick={() =>
                                handleDegreeChange(
                                  option.label,
                                  option.value
                                )
                              }
                            >
                              {option.label}
                            </Dropdown.Item>
                          </>
                        ))
                      )}
                      <div>
                        <div className="col-8">
                          <input
                            type="text"
                            placeholder="Type degree/certificate here you want to add..."
                            name="degreeCertificate"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSubmitCustomSchool();
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="formGroup">
              <label>Year of Completion</label>
              <input
                type="text"
                placeholder="Enter year"
                value={year === null ? "" : year}
                onChange={handleChange}
              />
            </div>
            <div className="formGroup">
              <label>Specialization</label>
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {specialize || "Please select an option"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="">
                      <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Search..."
                        onChange={(e) => setFilterValue(e.target.value)}
                        value={filterValue}
                      />
                    </div>

                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "scroll",
                      }}
                    >
                      {filteredSpecializeData.length === 0 ? (
                        <Dropdown.Item disabled>
                          No options found
                        </Dropdown.Item>
                      ) : (
                        filteredSpecializeData.map(
                          (option, index: number) => (
                            <>
                              <Dropdown.Item
                                key={index}
                                onClick={() =>
                                  handleSplecializeChange(
                                    option.label,
                                    option.value
                                  )
                                }
                              >
                                {option.label}
                              </Dropdown.Item>
                            </>
                          )
                        )
                      )}
                      <div>
                        <div className="col-8">
                          <input
                            type="text"
                            placeholder="Type degree/certificate here you want to add..."
                            name="degreeCertificate"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSubmitCustomSchool();
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="formGroup">
              <label>Description</label>
              <input
                type="textarea"
                placeholder="Enter Description"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
          </div>
       </Modal.Body>
       <ModalFooter>
         <div className="btn-hover-effect">
           <h6 onClick={onClose} className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
                             >
             <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
               Back
             </span>
             <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-lg-4 p-3">
               <img src={blackarrow} alt="" width={15} height={15} style={{transform: "rotate(-180deg)"}}/>
             </i>
           </h6>
         </div>
         <div className="btn-hover-effect">
           <h6 onClick={handleSave} className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 w-auto">
             <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
               Save
             </span>
             <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3 p-lg-4 p-3">
               <img src={whitearrow} alt="" width={15} height={15}/>
             </i>
           </h6>
         </div>         
       </ModalFooter>
     </form>
    </Modal>
   </>
  );
};

export default EducationModal;
