import React from "react";
import { setActiveStep } from "../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../enums";
import { useDispatch } from "react-redux";
import group from "../../assets/images/Group1686558045.png";
import whitearrow from "../../assets/images/whitearrow.svg";

const ConfirmModal = (props: any) => {
    const dispatch = useDispatch()

    const handleGoToMoreDetails = () => {
        dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_BASIC_INFO));
    }

    return (
        <div className="py-3">
            <div className="text-center">
                <figure><img src={group} alt="" className="mx-auto"/></figure>
            </div>
            <h5 className="font-28 text-center" >
            Would you like to Add More Details?
            </h5>

            <div className="row justify-content-center mt-4">
                {/* <div className="d-grid gap-1 col-lg-3 col-md-6">
                    <button
                        className="btn border btn-primary rounded-pill primaryBtn submitBtn m-0 py-3"
                        type="button"
                        onClick={handleGoToMoreDetails}
                    >
                        <span> Add Now
                        </span>
                    </button>
                </div> */}
                <div className="col-5 text-center my-3 btn-hover-effect">
                    <h6
                       onClick={() => props.onCloseModal()}
                        className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                    >
                        <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Not Now
                        </span>
                        <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={whitearrow} alt="" />
                        </i>
                    </h6>
                </div>
                <div className="col-5 text-center my-3 btn-hover-effect">
                    <h6
                        onClick={handleGoToMoreDetails}
                        className="btn bg-white arrowblue border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                    >
                        <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Add Now
                        </span>
                        <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                        </i>
                    </h6>
                    </div>
                {/* <div className="d-grid gap-1 col-lg-3 col-md-6">
                    <button
                        className="btn border btn-light rounded-pill border primaryBtn cancelBtn m-0 py-3"
                        type="button"
                        onClick={() => props.onCloseModal()}
                    >
                        <span>Not Now</span>
                    </button>
                </div> */}
                
               
            </div>
        </div>
    );
};
export default ConfirmModal;
