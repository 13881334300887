import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import dummy from "../../../assets/images/dummy.jpeg";
import star from "../../../assets/images/icon_star.svg";
import flag from "../../../assets/images/flag.svg";
import shareprofile from "../../../assets/images/share_profile.svg";
import aeroplane from "../../../assets/images/airoplane.svg";
import selfrating from "../../../assets/images/self_rating.svg";
import EditServicesModal from "../modals/EditServicesModal";
import EditBusinessGroupModal from "../modals/EditBusinessGroupModal";

const BusinessGroup = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Business Group
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img src={penbl} className="cursor-pointer ms-auto" alt="" onClick={openModal} />
          </span>
        </div>
      </div>
      <div className={styles.personalinfo}>
        <ul className="row align-items-center">
          <li className="col-xl-4 col-6">
            <span className="">Group Name</span>
            {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
            <label className="clamp clamp1">Developer Team</label>
          </li>
        </ul>
        <div className="">
          <h4 className="text-16 fw-semibold">Group Members</h4>
          <div className="row align-items-center">
            <div className="col-4">
              <div className="col border rounded-3">
                <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                  <div className="row align-items-center justify-content-center gx-2">
                    <div className="col-3 profile_icon">
                      <figure className="profileCircle">
                        <img
                          src={dummy}
                          alt=""
                          className="img-fluid rounded-circle"
                        />
                      </figure>
                    </div>
                    <div className="col-9 ps-2">
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="col-8">
                          <div className="newheadingstyle">
                            <h4 className="mb-0 text-16 clamp clamp1 fw-semibold">
                              Justin C
                            </h4>
                            <p className="text-10 my-0 clamp clamp1 ">
                              Robot Programmer{" "}
                            </p>
                            <p className="text-10 my-0 clamp clamp1 ">
                              Reviews : 20{" "}
                              <i>
                                <img src={star} alt="" />
                              </i>
                              4.5
                            </p>
                          </div>
                        </div>
                        <div className="col-2">
                          <i>
                            <img src={flag} alt="" />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center gx-2">
                    <div className="col-10">
                      <ul className="d-flex mb-0 align-items-center justify-content-start ps-0">
                        <li className="d-inline-block">
                          <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1">
                            Available
                          </p>
                          <p className="mb-0 ms-2 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                            <i className="me-1">
                              <img src={aeroplane} alt="" />
                            </i>
                            Willing totravel : Yes
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="col-2">
                      <figure className="bg-white rounded-circle px-2 py-1 mb-0 text-center">
                        <img src={shareprofile} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="skills">
                    <h4 className="fw-semibold text-14 mb-0">Top Skills</h4>
                    <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                      <li className="d-inline-block pe-2">
                        <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                          Figma
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="text-14">
                      Self Rating :{" "}
                      <i>
                        <img src={selfrating} alt="" />
                      </i>
                    </h5>
                  </div>
                  <div className="mt-3">
                    <div className="row align-items-center g-2">
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-primary w-100 text-10 px-1 text-center "
                        >
                          View Profile
                        </button>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-primary w-100 text-10 px-1 text-center "
                        >
                          Shared data
                        </button>
                      </div>

                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-white w-100 text-center border text-10 px-1"
                        >
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="col border rounded-3">
                <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                  <div className="row align-items-center justify-content-center gx-2">
                    <div className="col-3 profile_icon">
                      <figure className="profileCircle">
                        <img
                          src={dummy}
                          alt=""
                          className="img-fluid rounded-circle"
                        />
                      </figure>
                    </div>
                    <div className="col-9 ps-2">
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="col-8">
                          <div className="newheadingstyle">
                            <h4 className="mb-0 text-16 clamp clamp1 fw-semibold">
                              Justin C
                            </h4>
                            <p className="text-10 my-0 clamp clamp1 ">
                              Robot Programmer{" "}
                            </p>
                            <p className="text-10 my-0 clamp clamp1 ">
                              Reviews : 20{" "}
                              <i>
                                <img src={star} alt="" />
                              </i>
                              4.5
                            </p>
                          </div>
                        </div>
                        <div className="col-2">
                          <i>
                            <img src={flag} alt="" />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center gx-2">
                    <div className="col-10">
                      <ul className="d-flex mb-0 align-items-center justify-content-start ps-0">
                        <li className="d-inline-block">
                          <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1">
                            Available
                          </p>
                          <p className="mb-0 ms-2 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                            <i className="me-1">
                              <img src={aeroplane} alt="" />
                            </i>
                            Willing totravel : Yes
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="col-2">
                      <figure className="bg-white rounded-circle px-2 py-1 mb-0 text-center">
                        <img src={shareprofile} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="skills">
                    <h4 className="fw-semibold text-14 mb-0">Top Skills</h4>
                    <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                      <li className="d-inline-block">
                        <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                          Figma
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="text-14">
                      Self Rating :{" "}
                      <i>
                        <img src={selfrating} alt="" />
                      </i>
                    </h5>
                  </div>
                  <div className="mt-3">
                    <div className="row align-items-center g-2">
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-primary w-100 text-10 px-1 text-center "
                        >
                          View Profile
                        </button>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-primary w-100 text-10 px-1 text-center "
                        >
                          Shared data
                        </button>
                      </div>

                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-white w-100 text-center border text-10 px-1"
                        >
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="col border rounded-3">
                <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                  <div className="row align-items-center justify-content-center gx-2">
                    <div className="col-3 profile_icon">
                      <figure className="profileCircle">
                        <img
                          src={dummy}
                          alt=""
                          className="img-fluid rounded-circle"
                        />
                      </figure>
                    </div>
                    <div className="col-9 ps-2">
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="col-8">
                          <div className="newheadingstyle">
                            <h4 className="mb-0 text-16 clamp clamp1 fw-semibold">
                              Justin C
                            </h4>
                            <p className="text-10 my-0 clamp clamp1 ">
                              Robot Programmer{" "}
                            </p>
                            <p className="text-10 my-0 clamp clamp1 ">
                              Reviews : 20{" "}
                              <i>
                                <img src={star} alt="" />
                              </i>
                              4.5
                            </p>
                          </div>
                        </div>
                        <div className="col-2">
                          <i>
                            <img src={flag} alt="" />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center gx-2">
                    <div className="col-10">
                      <ul className="d-flex mb-0 align-items-center justify-content-start ps-0">
                        <li className="d-inline-block">
                          <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1">
                            Available
                          </p>
                          <p className="mb-0 ms-2 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                            <i className="me-1">
                              <img src={aeroplane} alt="" />
                            </i>
                            Willing totravel : Yes
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="col-2">
                      <figure className="bg-white rounded-circle px-2 py-1 mb-0 text-center">
                        <img src={shareprofile} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="skills">
                    <h4 className="fw-semibold text-14 mb-0">Top Skills</h4>
                    <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                      <li className="d-inline-block">
                        <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                          Figma
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="text-14">
                      Self Rating :{" "}
                      <i>
                        <img src={selfrating} alt="" />
                      </i>
                    </h5>
                  </div>
                  <div className="mt-3">
                    <div className="row align-items-center g-2">
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-primary w-100 text-10 px-1 text-center "
                        >
                          View Profile
                        </button>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-primary w-100 text-10 px-1 text-center "
                        >
                          Shared data
                        </button>
                      </div>

                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-white w-100 text-center border text-10 px-1"
                        >
                          Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" mt-3">
          <h4 className="text-14 fw-semibold text-blue">+ Add more</h4>
        </div>
      </div>
      <EditBusinessGroupModal showModal={showModal} closeModal={closeModal} title="Edit Business Group"/>

    </div>
  );
};

export default BusinessGroup;
