import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import penciledit from "../../../assets/images/penciledit.svg";
import downloadbalck from "../../../assets/images/download_black.svg";
import printblack from "../../../assets/images/print_black.svg";
import certificate from "../../../assets/images/certificate.png";
import EditCertificateModal from "../modals/EditCertificateModal";
import { CertificateProps } from "../interface";

const Certifications:React.FC<CertificateProps> = ({refresh,docFile}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  console.log(docFile,"certifications ")
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <h5 className="text-20 fw-semibold mb-0 ">Certifications </h5>
        </div>
        <div className="col-2">
          <div className=" text-end">
            <span>
              <img src={penbl} className="cursor-pointer ms-auto" alt="" onClick={openModal}/>
            </span>
          </div>
        </div>
      </div>
      <ul className={styles.experienceSet}>
        <div className="row align-items-center justify-content-center">
          <li className="col-6">
            <div>
              <h4>Designing Certificate</h4>
              <h5>Govt College Chandigarh</h5>
              <h6>Start Date 12 Jun 2022 — End Date 12 Jun 2023</h6>
              <div className="position-relative">
                <figure>
                  <img src={certificate} alt="" className="img-fluid" />
                </figure>
                <ul
                  className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                  style={{ gap: "15px" }}
                >
                  <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={penciledit} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Edit</span>
                  </li>
                  <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={printblack} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Print</span>
                  </li>
                  <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={downloadbalck} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Download</span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="col-6">
            <div>
              <h4>Designing Certificate</h4>
              <h5>Govt College Chandigarh</h5>
              <h6>Start Date 12 Jun 2022 — End Date 12 Jun 2023</h6>
              <div className="position-relative">
                <figure>
                  <img src={certificate} alt="" className="img-fluid" />
                </figure>
                <ul
                  className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                  style={{ gap: "15px" }}
                >
                  <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={penciledit} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Edit</span>
                  </li>
                  <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={printblack} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Print</span>
                  </li>
                  <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={downloadbalck} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Download</span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <h4 className="text-14 fw-semibold text-blue">+ Add more</h4>
          </li>
        </div>
      </ul>
      <EditCertificateModal showModal={showModal} closeModal={closeModal} title="Edit Certifications" refresh={refresh}/>
    </div>
  );
};

export default Certifications;
