import React from "react";
import styles from "../../styles/styles.module.css";

import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { RootState } from "../../../../../redux/store";
import storage from "../../../../../utils/storage";
import {
  setActiveStep,
  setLegalDetails,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";

const AddLegalInfo: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { legalDetails } = useSelector((state: RootState) => state.addContract);
  const { isAutomateAmerica, isLegalResidence, isVisa } = legalDetails;

  const formIk: any = useFormik({
    initialValues: { isAutomateAmerica, isLegalResidence, isVisa },
    onSubmit: () => { },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_MANUFACTURER));
  };

  const handleSkip = () => {
    dispatch(
      setLegalDetails({
        isAutomateAmerica: false,
        isLegalResidence: false,
        isVisa: false,
      })
    );
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_MANUFACTURER));
  };

  const onInputChange = (value: string | boolean, key: string) => {
    dispatch(setLegalDetails({ ...legalDetails, [key]: value }));
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">

            <form className="h-100" onSubmit={formIk.handleSubmit}>
              <div
                className={styles.registerOuter}
                style={{ position: "relative" }}
              >
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div
                          className={styles.registerLeft}
                        >
                           <h4 className="text-white text-32 fw-semibold">
                              Non Mandatory Hourly <br /> Contract Fields
                            </h4>
                          {/* <p>Add a Legal information of the work that you need</p> */}
                          <div className="col-lg-11 col-12"
                      > 
                          <figure>
                            <img
                              src={Occupations}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className={styles.signUPContainerd}>
                          <div
                            className={`${styles.registerRight} ${styles.editors}`}
                          >
                           
                            <div
                              className={styles.fromGroup}
                              style={{
                                marginBottom: "30px",
                                marginTop: "30px",
                              }}
                            >

                            </div>
                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              <div className={styles.ratedRadio}>
                                <label> US Legal Residents Required?</label>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="isLegalResidence"
                                      checked={isLegalResidence}
                                      onChange={(e) =>
                                        onInputChange(true, "isLegalResidence")
                                      }
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="isLegalResidence"
                                      checked={!isLegalResidence}
                                      onChange={(e) =>
                                        onInputChange(false, "isLegalResidence")
                                      }
                                    />
                                    <label className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {!isLegalResidence && (
                                <div
                                  className={styles.ratedRadio}
                                  style={{ marginTop: "30px" }}
                                >

                                  {" "}
                                  <label>   Visa that allows to work legally in the Us
                                    Required?</label>


                                  <div className={styles.rateradio}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="isVisa"
                                        id="isVisa"
                                        checked={isVisa}
                                        onChange={(e) =>
                                          onInputChange(true, "isVisa")
                                        }
                                      />
                                      <label className="form-check-label">
                                        Yes
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="isVisa1"
                                        id="isVisa1"
                                        checked={!isVisa}
                                        onChange={(e) =>
                                          onInputChange(false, "isVisa")
                                        }
                                      />
                                      <label className="form-check-label">
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
  <div className="col-4 text-start my-3 btn-hover-effect">
    <h6
      onClick={() => handleGoBack()}
      className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
    >
      <span className="px-lg-4 px-2 text-12 fw-semibold">Back</span>
      <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
        <img src={blackarrow} alt="" />
      </i>
    </h6>
  </div>
  <div className="col-8 d-flex justify-content-between">
    <div className="col-4 text-end my-3 btn-hover-effect">
      <button
        onClick={handleSkip}
        className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2"
      >
        <span className="px-lg-4 px-2 text-12 fw-semibold text-black">Skip</span>
      </button>
    </div>
    <div className="col-4 text-end my-3 btn-hover-effect">
      <h6
        onClick={handleGoToNextStep}
        className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
      >
        <span className="px-lg-4 px-2 text-12 fw-semibold">Next</span>
        <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
          <img src={whitearrow} alt="" />
        </i>
      </h6>
    </div>
  </div>
</div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLegalInfo;
