import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import security from "../../../assets/images/security.svg";
import downloadbalck from "../../../assets/images/download_black.svg";
import printblack from "../../../assets/images/print_black.svg";
import penciledit from "../../../assets/images/penciledit.svg";
import photoid from "../../../assets/images/photoid.jpg";
import EditPhotoModal from "../modals/EditPhotoModal";

const PhotoId = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">Photo ID</h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className=" text-end">
            <span>
              <img src={penbl} className="cursor-pointer ms-auto" alt="" onClick={openModal}/>
            </span>
          </div>
        </div>
      </div>
      <ul className={styles.experienceSet}>
        <div className="row align-items-center justify-content-start">
          <li className="col-6">
            <div>
              <h4>Johns Photo ID.pdf</h4>

              <div className="position-relative">
                <figure>
                  <img src={photoid} alt="" className="img-fluid" />
                </figure>
                <ul
                  className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                  style={{ gap: "15px" }}
                >
                  <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={penciledit} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Edit</span>
                  </li>
                  <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={printblack} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Print</span>
                  </li>
                  <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={downloadbalck} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Download</span>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className={styles.note}>
              <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
                <div className="col-lg-1 col-2 text-center">
                  <i>
                    <img src={security} alt="" />
                  </i>
                </div>
                <div className="col-lg-11 col-10">
                  <p className="text-14 fw-medium mb-0">
                    Please provide your financial information securely to
                    facilitate payments. Your security is extremely important to
                    us, and only Automate America Financial Administration will
                    have access to this information.
                  </p>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
      <EditPhotoModal showModal={showModal} closeModal={closeModal} title="Edit Photo ID"/>
    </div>
  );
};

export default PhotoId;
