import React from "react";
import arrowwithoutcircle_green from "../../../assets/images/arrow_withoutcircle_green.svg";
import styles from "../styles/style.module.css";
import findprofessionals from "../../../assets/images/find_professionals.svg";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";


const FindProfessional = () => {
  const navigate = useNavigate();
  return (
    <>
      <li className={styles.findprofessionals}>
          
        <div className="row align-items-center">
          <div className="col-3 px-0">
            <i>
              <img src={findprofessionals} alt="" className="img-fluid" />
            </i>
          </div>
          <div
            className="col-9"
            onClick={() => navigate(APP_ROUTES.FIND_PROFESSIONALS)}
          >
            <div className="newheadingstyle">
              <h3 className="mb-0 text-white">Find a Professinals</h3>
              <p className=" text-white">
                Explore Our Comprehensive Contract Repository for Seamless
                Business Solutions.
              </p>
              <button className="btn btn-white text-16">
                Find Professinals Now
                <i className="ms-2">
                  <img src={arrowwithoutcircle_green} alt="" />
                </i>
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default FindProfessional;