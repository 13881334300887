 export interface ApiResponse{
  data:JobResponse[];
  pagination?: Pagination;
}

export interface Pagination {
  currentPage: number;
  lastPage: number;
  nextPage: number;
  limit: number;
  totalRecord: number;
  totalPage: number;
}


export interface JobResponse {
  _id: string;
  userId: string;
  workType: string;
  address: string;
  countryId: string;
  createdAt: string;
  description: string;
  eligibilityQuestions: EligibilityQuestion[];
  expenseReimbursed: boolean;
  industriesIds: string[];
  interviewQuestions: InterviewQuestion[];
  isApplied: boolean;
  isHealthInsurance: boolean;
  isSameCitizenship: boolean;
  isVacationAndTimeOff: boolean;
  location?: {
    type: string;
    coordinates: [number, number];
  };
  manufacturerIds: string[];
  manufacturerModelIds: string[];
  occupation: Occupation;
  occupationIds: string[];
  payForAirfare: boolean;
  payForHotel: boolean;
  payForRentalCar: boolean;
  rates: Rates;
  secondaryOccupationIds: string[];
  skilledInfo: any[];
  softwareLicence: SoftwareLicence[];
  status: boolean;
  technicalSkills: TechnicalSkill[];
  times: Times;
  travelForJob: boolean;
  updatedAt: string;
  workEnvironment: string[];
  workNumber: number;
  workStatus: string;
  workTypeState: string;
  expense:Expenses
  // Pagination: {
  //   currentPage: number;
  //   lastPage: number;
  //   nextPage: number;
  //   limit: number;
  //   totalRecord: number;
  //   totalPage: number;}
  planType:string
}

interface EligibilityQuestion {
  question: string;
  required: boolean;
  _id: string;
}

interface InterviewQuestion {
  question: string;
  required: boolean;
  _id: string;
}

interface Occupation {
  _id: string;
  name: string;
}

interface Rates {
  baseRate: number;
  overTimeRate: number;
  doubleTimeRate: number;
}

interface Expenses {
  dailyPerDiem: number;
  airfareAllowance: number;
 
}

interface TechnicalSkill {
  manufacturerIds: string;
  manufacturerModelIds: string;
}

interface Times {
  startDate: string;
  shift: string;

  endDate?: string; // Optional if it's not always provided
  plannedHoursPerDay:number;
  plannedDaysPerWeek:number;
  noOfPepole:number;
  duration:number
}

export interface Pagination {
  currentPage: number;
  lastPage: number;
  nextPage: number;
  limit: number;
  totalRecord: number;
  totalPage: number;
}


interface SoftwareLicence {
  softwareLicenceId: string;
  isMandatory: boolean;
  isGoodToHave: boolean;
  softwareLicenceDetails: {           // Details about the software license
    _id: string;                      // Unique ID of the software license details
    name: string;                     // Name of the software license
  };
}

export const GET_RECOMMENDED_TYPE = {
  LIST_VIEW: 'listview',
  MAP_VIEW: 'mapview',
};